import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContentHeader from "../../../component/common/ContentHeader";
import ServerSideTable from "./ServerTable";
import {
  CONTENT_STATES,
  DEFAULT_PAGE_SIZE,
} from "../../../constants/GeneralConstant";
import { formatDate } from "../../../component/common/DateFormatter";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import DeleteConfirmationDialog from "../../../component/common/DeleteConfirmationDialog";
import {
  fetchFAQs,
  deleteFAQ,
  toggleFAQActivation,
} from "../../../store/actions/faqs";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../constants/URL";
import { setFilteredQueryParams } from "../../../store/actions/filter";
import { getStatusClass } from "../../../component/common/GeneralHelper";

const FAQList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [listData, setListData] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const { params: filterParams } = useSelector((state) => state.filter);

  const { FAQS_EDIT } = URL;

  const { result, meta } = useSelector((state) => state.faqs.list);

  const fetchData = async () => {
    try {
      setLoading(true);
      let payload = {
        ...filterParams,
        search: searchQuery,
        page: page ? page : 1,
        perPage: pageSize,
      };
      dispatch(fetchFAQs(payload));
      dispatch(setFilteredQueryParams(payload));
    } catch (error) {
      setRows([]); // Reset rows on error
      setRowCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortModel, searchQuery]);

  useEffect(() => {
    setRows(result || []); // Ensure rows is always an array
    setRowCount(meta?.total || 0); // Ensure totalCount is always a number
    setListData(result);
  }, [result]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getCategoriesLabel = (categories) => {
    let data = categories
      .map((m) => m.title["EN"] || m.title["LU"] || m.title["RY"])
      .join();
    return data;
  };

  const getUpdatedDate = (item) => {
    return item.updatedAt
      ? `${formatDate(item.updatedAt).date} | ${
          formatDate(item.updatedAt).time
        } by ${
          item.updatedBy && item.updatedBy.fullname
            ? item.updatedBy.fullname
            : "Admin"
        }`
      : `${formatDate(item.createdAt).date} | ${
          formatDate(item.createdAt).time
        } by ${
          item.updatedBy && item.updatedBy.fullname
            ? item.updatedBy.fullname
            : "Admin"
        }`;
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 150,
      renderCell: (params) =>
        params.value["EN"] || params.value["LU"] || params.value["RY"],
    },
    {
      field: "categories",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => getCategoriesLabel(params.value),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className={getStatusClass(params.row.status)}>
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      flex: 1,
      renderCell: (params) => {
        return getUpdatedDate(params.row);
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );
        const HandleActivation = (params) => {
          const res = listData?.find((obj) => {
            return obj.id === params.id;
          });

          dispatch(toggleFAQActivation(params.id, res))
            .then(() => {
              if (res.status === CONTENT_STATES.PUBLISHED) {
                toast.success("Successfully deactivated!");
              } else {
                toast.success("Successfully activated!");
              }
            })
            .catch(() => {
              toast.error("Failed to activate/deactivate resource!");
            })
            .finally(() => {
              let payload = { ...filterParams };
              delete payload.type;
              dispatch(fetchFAQs(payload));
            });
        };
        const faq = listData?.find((obj) => obj.id === params.id);

        let activation;
        if (faq && faq.status === CONTENT_STATES.PUBLISHED) {
          activation = (
            <Tooltip title="deactivate">
              <VisibilityOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }
        const [open, setOpen] = useState(false);

        const handleDelete = (params) => {
          dispatch(deleteFAQ(params.id))
            .then(() => {
              // Refresh the article list
              let payload = { ...filterParams };
              delete payload.type;
              dispatch(fetchFAQs(payload)).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete resource!");
            });
        };

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                onClick={() => {
                  navigate(FAQS_EDIT.replace(":id", params.id));
                }}
              />
            </Tooltip>
            {activation}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon onClick={() => setOpen(true)} />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
  ];

  const onPageChange = (newPage) => {
    let filters = { ...filterParams, page: newPage + 1 };
    dispatch(setFilteredQueryParams(filters));
    setPage(newPage + 1);
  };

  const onPageSizeChange = (newPageSize) => {
    let filters = { ...filterParams, limit: newPageSize };
    dispatch(setFilteredQueryParams(filters));
    setPageSize(newPageSize);
  };
  const onSortModelChange = (newSortModel) => setSortModel(newSortModel);

  return (
    <>
      <ContentHeader
        header="FAQs"
        route="/faqs/add"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Title"
        isNearBy={false}
        type={"FAQS"}
        count={meta?.total ?? 0}
      />
      <ServerSideTable
        searchQuery={searchQuery}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        page={page}
        pageSize={pageSize}
        listData={listData}
        setSortModel={setSortModel}
        filterParams={filterParams}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        fetchData={fetchData}
        sortModel={sortModel}
      />
    </>
  );
};

export default FAQList;

import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import SideList from "./sideList";
import { DashboardNavbar } from "./dashboard-navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  getEventCategories,
} from "../../store/actions/category";
// import { clearCurrentRescource } from '../../store/actions/currentArticle';
import { getSpecies } from "../../store/actions/species";
import { getScoreData } from "../../store/actions/scoreCard";
import { setDrawerClose, setDrawerOpen } from "../../store/actions/toolbar";

export default function Dashboard({ user, isPermitted }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const drawerOpen = useSelector((state) => state.toolbar.drawerOpen);

  useEffect(() => {
    dispatch(getSpecies());
    dispatch(getEventCategories());
    dispatch(getCategories());
    dispatch(getScoreData());
    if (id === undefined) {
      // dispatch(clearCurrentRescource());
    }
  }, [dispatch, id]);

  return (
    <>
      <CssBaseline />

      <DashboardNavbar
        user={user}
        onSidebarOpen={() => {
          !!drawerOpen ? dispatch(setDrawerClose()) : dispatch(setDrawerOpen());
        }}
      />
      <SideList user={user} isPermitted={isPermitted} />
      <Box
        sx={{
          marginTop: "1em",
          marginLeft: drawerOpen ? "19em" : "1.5em",
          marginRight: "1.5em",
          "@media (max-width: 767px)": {
            marginLeft: drawerOpen ? "1.5em" : "1.5em",
          },
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}

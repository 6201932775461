import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import ContentHeader from "../../../component/common/ContentHeader";
import ServerSideTable from "./ServerTable";
import { EVENT_REQUEST_URL } from "../../../constants/apiUrls";

import {
  CONTENT_STATES,
  DEFAULT_PAGE_SIZE,
} from "../../../constants/GeneralConstant";
import { formatDate } from "../../../component/common/DateFormatter";
import { Tooltip } from "@mui/material";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../constants/URL";
import {
  setFilteredEventQueryParams,
  setFilteredQueryParams,
} from "../../../store/actions/filter";
import {
  getFullName,
  getStatusClass,
} from "../../../component/common/GeneralHelper";
import {
  getEventRequestsData,
  markRequestAsRead,
  setSelectedEventRequest,
} from "../../../store/actions/eventRequests";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

const EventRequestListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isEventRequest = location.pathname === EVENT_REQUEST_URL;

  const { id } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [listData, setListData] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const { eventParams } = useSelector((state) => state.filter);

  const { result, meta } = useSelector((state) => state.eventRequests.list);

  const fetchData = async () => {
    try {
      setLoading(true);
      let payload = {
        ...eventParams,
        searchText: searchQuery,
        page: page ? page : 1,
        perPage: pageSize,
      };
      dispatch(getEventRequestsData(payload));
      dispatch(setFilteredEventQueryParams(payload));
    } catch (error) {
      setRows([]); // Reset rows on error
      setRowCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortModel, searchQuery]);

  useEffect(() => {
    setRows(result || []); // Ensure rows is always an array
    setRowCount(meta?.total || 0); // Ensure totalCount is always a number
    setListData(result);
  }, [result]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getCategoriesLabel = (categories) => {
    let data = categories
      .map((m) => m.title["EN"] || m.title["LU"] || m.title["RY"])
      .join();
    return data;
  };

  const getUpdatedDate = (item) => {
    return item.updatedAt
      ? `${formatDate(item.updatedAt).date} | ${
          formatDate(item.updatedAt).time
        } by ${getFullName(item.createdBy)}`
      : `${formatDate(item.createdAt).date} | ${
          formatDate(item.createdAt).time
        } by ${getFullName(item.createdBy)}`;
  };

  const handleRefresh = () => {
    fetchData();
  };

  const handleRowSelection = (data) => {
    const selectedRowId = data.row.id;

    dispatch(
      setSelectedEventRequest(list.find((req) => req.id === selectedRowId))
    );
    navigate(`/event-requests/${selectedRowId}/view`);
  };

  const handleMarkAsRead = (id) => {
    dispatch(markRequestAsRead({ ids: [id] }));
    fetchData();
  };

  const handleMarkAllAsRead = () => {
    const payload = {
      ids: listData?.map((item) => item.id),
    };
    dispatch(markRequestAsRead(payload));
    fetchData();
  };

  const columns = [
    {
      field: "",
      headerName: "",
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Mark As Read">
              {!params.row.markAsRead ? (
                <MarkunreadOutlinedIcon
                  onClick={() => handleMarkAsRead(params.row.id)}
                />
              ) : (
                <DraftsOutlinedIcon />
              )}
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 150,
      renderCell: (params) =>
        params.value["EN"] || params.value["LU"] || params.value["RY"],
    },
    {
      field: "categories",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => getCategoriesLabel(params.value),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div className={getStatusClass(params.row.status)}>
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Created By",
      flex: 1,
      renderCell: (params) => {
        return getUpdatedDate(params.row);
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View">
              <VisibilityIcon
                onClick={() => {
                  dispatch(
                    setSelectedEventRequest(
                      listData.find((req) => req.id === params.row.id)
                    )
                  );
                  navigate(`/event-requests/${params.row.id}/view`);
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const onPageChange = (newPage) => {
    let filters = { ...eventParams, page: newPage + 1 };
    dispatch(setFilteredQueryParams(filters));
    setPage(newPage + 1);
  };

  const onPageSizeChange = (newPageSize) => {
    let filters = { ...eventParams, limit: newPageSize };
    dispatch(setFilteredQueryParams(filters));
    setPageSize(newPageSize);
  };
  const onSortModelChange = (newSortModel) => setSortModel(newSortModel);

  return (
    <>
      <ContentHeader
        header={"Event Requests"}
        route={"/new-events"}
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Title"
        isNearBy={false}
        type={"Event Requests"}
        count={meta?.total ?? 0}
        isEventRequest={isEventRequest}
        handleRefresh={handleRefresh}
        handleRowSelection={handleRowSelection}
        handleMarkAllAsRead={handleMarkAllAsRead}
      />
      <ServerSideTable
        searchQuery={searchQuery}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        page={page}
        pageSize={pageSize}
        listData={listData}
        setSortModel={setSortModel}
        filterParams={eventParams}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        fetchData={fetchData}
        sortModel={sortModel}
      />
    </>
  );
};

export default EventRequestListing;

import { Typography, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";

const FilteredStatus = ({
  values,
  activation,
  handleChipClick,
  filteredData,
  handleActivationChipClick,
}) => {
  const selectedStatus = filteredData.status || [];
  const selectedActivation = filteredData.isGated || [];
  const resourceType = useSelector((state) => state.resourceType);

  return (
    <Box sx={{ margin: "1em" }}>
      <Typography variant="h6" sx={{fontSize: '14px'}}>Status</Typography>
      <Box sx={{ 
        marginTop: "0.5em",
        display: 'flex',
        gap: '6px',
        flexWrap: 'wrap', 
      }}>
        {values.map((value) => (
          <Chip
            key={value}
            label={value.charAt(0).toUpperCase() + value.slice(1)}
            clickable
            color={selectedStatus.includes(value) ? "primary" : "default"}
            onClick={() => handleChipClick(value)}
            sx={{
              border: "1px solid #808080",
              alignItems: "center",
            }}
          />
        ))}
        {!["Tick", "Events", "Products", "Faqs"].includes(resourceType.type) &&
          activation.map((value) => (
            <Chip
              key={value}
              label={value.charAt(0).toUpperCase() + value.slice(1)}
              clickable
              color={selectedActivation.includes(value) ? "primary" : "default"}
              onClick={() => handleActivationChipClick(value)}
              style={{
                border: "1px solid #808080",
                position: "relative",
              }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default FilteredStatus;
import {
  Avatar,
  Button,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import BlobService from "../../services/blob-service";

import {
  createPublisher,
  updatePublisher,
  getPublishers,
} from "../../store/actions/Publishor";

import { setPublisherId } from "../../store/actions/currentArticle";
import { WithHeader } from "./WithHeader";
import SelectionLimitDialog from "./SelectionLimitDialog";
import { localizedPublisher } from "../../store/reducers/selector";

const PublisherDetail = () => {
  const dispatch = useDispatch();
  const [publisherName, setPublisherName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [publisherNameError, setPublisherNameError] = useState("");
  const [selected, setSelected] = useState([]);
  // const [openDialog, setOpenDialog] = useState(false);
  const selectedPublishers = useSelector((state) => state.article.publisherIds);

  // console.log(
  //   "state:",
  //   useSelector((state) => state.article)
  // );

  const publisherData = useSelector(localizedPublisher);

  let selectedPublishersId = selectedPublishers
    .flatMap((publisher) => publisher.id)
    .filter((id) => publisherData.some((publisher) => publisher.id === id));

  const languageSelector = useSelector((state) => state.article.language);
  const publisherUpdated = useSelector(
    (state) => state.publisherData?.publisherUpdated
  );

  useEffect(() => {
    dispatch(getPublishers());
  }, [dispatch, publisherUpdated]);

  const uploadProfilePic = (publisherId, file) => {
    const filename = BlobService.generateFileName(file);
    const fileSize = file.size / 1024 / 1024; // convert to MB
    if (fileSize > 20) {
      toast.error(`Image size exceeds 20 MB limit`);
      return;
    }
    BlobService.uploadImage(filename, file).then(() => {
      dispatch(updatePublisher(publisherId, filename));
      dispatch(getPublishers());
    });
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleTogglePublisher = (id, fullname) => () => {
    const currentIndex = selected.indexOf(fullname);
    const selectedCount = selectedPublishers.length;
    const isCategorySelected = selectedPublishersId.includes(id);

    if (selectedCount === 1 && !isCategorySelected) {
      setDialogOpen(true);
      return;
    }

    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(fullname);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
    dispatch(setPublisherId(id));
  };

  const removeProfilePic = (publisherId, filename) => {
    BlobService.deleteImage(filename).then(() => {
      dispatch(updatePublisher(publisherId, ""));
      dispatch(getPublishers());
    });
  };

  const handleAddPublisher = () => {
    if (!publisherName.trim()) {
      setPublisherNameError("Please enter a valid publisher name.");
      return;
    }
    setPublisherNameError("");
    dispatch(createPublisher(publisherName.trim(), languageSelector));
    setPublisherName("");
    setPublisherNameError(""); // Reset the error message
  };

  const getPublisherRow = (publisher) => {
    const fullName = publisher?.fullname?.[languageSelector];
    const [firstName, lastName] =
      publisher?.fullname?.[languageSelector]?.split(" ");
    let initials;

    if (lastName) {
      initials =
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    } else {
      initials = firstName.substring(0, 2).toUpperCase();
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          '@media (max-width: 767px)': {
            gap: '10px',
            width: '150%',
          },
        }}
      >
        <Stack
          key={publisher.id}
          alignItems="center"
          direction="row"
          sx={{my: 1}}
        >
          <FormControlLabel
            sx={{
              m: 0,
            }}
            value={publisher.id}
            control={
              <Checkbox
                checked={selectedPublishersId.includes(publisher.id)}
                onChange={handleTogglePublisher(publisher.id, fullName)}
              />
            }
          />
          <Avatar
            alt={fullName}
            sx={{ width: 40, height: 40,  }}
            src={BlobService.getImageUri(publisher.profilePic)}
          >
            {initials}
          </Avatar>
          <CardHeader
            sx={{ 
              p: 0,
            }} 
            titleTypographyProps={{
              fontSize: '14px', // Adjusts the font size of the MuiTypography-root
              ml: 1.5,
            }}
           title={fullName} />
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Button
            color="primary"
            size="sm"
            aria-label="upload picture"
            component="label"
            className="authorUpload"
            // disabled={!author[0]}
          >
            Upload Image
            <input
              id="upload-image"
              hidden
              accept=".jpeg, .png, .jpg"
              type="file"
              onChange={(e) => {
                uploadProfilePic(publisher.id, e.target.files[0]);
                e.target.value = "";
              }}
            />
          </Button>
          <Button
            color="primary"
            aria-label="remove picture"
            component="label"
            className="authorUpload"
            disabled={!publisher.profilePic}
            onClick={(e) =>
              removeProfilePic(publisher.id, publisher.profilePic)
            }
          >
            Remove Image
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ 
        display: "flex", 
        flexDirection: "column" 
      }}>
        <Box className="cs-scroll" sx={{ maxHeight: 400, overflowY: "auto" }}>
          {publisherData?.length > 0 &&
            publisherData?.map((publisher) => getPublisherRow(publisher))}
        </Box>
        <TextField
          value={publisherName}
          onChange={(event) => {
            setPublisherName(event.target.value);
            setPublisherNameError("");
          }}
          fullWidth
          placeholder="Start typing to add a new publisher..."
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 0, // set borderRadius to 0
            },
          }}
          InputProps={{
            endAdornment: publisherName && (
              <Button
                size="sm"
                sx={{width: '200px'}}
                variant="contained"
                onClick={handleAddPublisher}
              >
                Add Publisher Name
              </Button>
            ),
          }}
        />
        {publisherNameError && (
          <span style={{ color: "red", marginTop: "5px" }}>
            {publisherNameError}
          </span>
        )}
        <SelectionLimitDialog
          data="Publisher"
          count="1"
          open={dialogOpen}
          onClose={handleCloseDialog}
        />
      </Box>
    </>
  );
};

export default WithHeader(PublisherDetail, "Publisher Details *");

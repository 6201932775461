import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SourceIcon from "@mui/icons-material/Source";
import StairsIcon from "@mui/icons-material/Stairs";
import ArticleIcon from "@mui/icons-material/Article";
import VideoIcon from "@mui/icons-material/Videocam";
import CollectionsIcon from "@mui/icons-material/Collections";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import PestControlIcon from "@mui/icons-material/PestControl";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import TableChartIcon from "@mui/icons-material/TableChart";
import BackupTableRoundedIcon from "@mui/icons-material/BackupTableRounded";
import PersonIcon from "@mui/icons-material/Person";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PolicyIcon from "@mui/icons-material/Policy";
import NearMeIcon from "@mui/icons-material/NearMe";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { PERMISSIONS } from "../../constants/Permissions";
import { setDrawerClose, setDrawerOpen } from "../../store/actions/toolbar";
import { useDispatch, useSelector } from "react-redux";
import { Box, Collapse, Drawer } from "@mui/material";
import { ExpandLess, ExpandMore, Source } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { setCurrentResourceType } from "../../store/actions/resourceType";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import "./style.scss";
const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Styles for a closed drawer
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// Header styles for the drawer
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),

      width: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);
const MenuItem = ({ isGrouped = false, item, selectedLink, onItemClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => onItemClick(item.link)}
        selected={selectedLink === item.link}
        sx={{
          backgroundColor: "#0f697a",
          "&:hover": { backgroundColor: "#123c45" },
          pl: isGrouped ? 4 : undefined, // Adds spacing if grouped
          borderLeft: isGrouped ? "5px solid #fff" : undefined,
          borderBottom: "1px solid #298395",
          backgroundColor: isGrouped ? "#055160" : "#0f697a",
        }}
      >
        <ListItemIcon sx={{ color: "#fff", minWidth: 36 }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.title}
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
              color: "#fff",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const CollapsibleGroup = ({
  group,
  selectedLink,
  onItemClick,
  isCollapsed,
  onToggle,
  icon,
}) => (
  <>
    <ListItem
      sx={{
        borderBottom: "1px solid #298395",
        backgroundColor: "#146879",

        "& .MuiTypography-root": {
          fontSize: 14,
          color: "#fff",
        },
        "&: hover": {
          backgroundColor: "#123c45",
        },
      }}
    >
      <ListItemButton
        sx={{
          borderRadius: "6px",
          padding: "0",
          "&: hover": {
            backgroundColor: "#123c45",
          },
        }}
        onClick={() => onToggle(group.link)}
      >
        <ListItemIcon sx={{ color: "#fff", minWidth: 36 }}>{icon}</ListItemIcon>
        <ListItemText className="group-list-panel" primary={group.title} />
        {isCollapsed(group.link) ? (
          <ExpandLess sx={{ fontSize: "1.25rem", color: "#fff" }} />
        ) : (
          <ExpandMore sx={{ fontSize: "1.25rem", color: "#fff" }} />
        )}
      </ListItemButton>
    </ListItem>
    <Collapse in={isCollapsed(group.link)} timeout="auto" unmountOnExit>
      {group.items.map(
        (item) =>
          item.show === true && (
            <MenuItem
              isGrouped={true}
              key={item.link}
              item={item}
              selectedLink={selectedLink}
              onItemClick={onItemClick}
            />
          )
      )}
    </Collapse>
  </>
);

const SideList = ({ isPermitted }) => {
  const [selectedLink, setSelectedLink] = useState("article");
  const [openItems, setOpenItems] = useState({
    "content-management": true,
    gamification: true,
    "event-management": true,
  });
  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let drawerOpen = useSelector((state) => state.toolbar.drawerOpen);
  const resourceType = useSelector((state) => state.resourceType);

  useEffect(() => {
    sessionStorage.setItem("selectedLink", selectedLink);
    dispatch(
      setCurrentResourceType(
        selectedLink.charAt(0).toUpperCase() +
          selectedLink.slice(1).toLowerCase()
      )
    );
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch(setDrawerClose());
    } else {
      dispatch(setDrawerOpen());
    }
  }, []);

  const handleItemClick = (link) => {
    navigate(link);
    setSelectedLink(link);
    sessionStorage.setItem("selectedLink", link);
    dispatch(
      setCurrentResourceType(
        link.charAt(0).toUpperCase() + link.slice(1).toLowerCase()
      )
    );
  };

  const toggleCollapse = (key) => {
    setOpenItems((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const isCollapsed = (key) => openItems[key] || false;
  const groupedList = useMemo(
    () => [
      {
        title: "Dashboard",
        link: "dashboard",
        items: [
          {
            title: "Dashboard",
            icon: <DashboardOutlinedIcon fontSize="small" />,
            link: "dashboard",
            show: true, // Always visible
          },
        ],
      },
      {
        title: "Content Management",
        icon: <SourceIcon fontSize="small" />,
        link: "content-management",
        items: [
          {
            title: "Articles",
            icon: <ArticleIcon fontSize="small" />,
            link: "article",
            parent: "content-management",
            show: isPermitted(PERMISSIONS.ARTICLES), // Visibility based on permission
          },
          {
            title: "Videos",
            icon: <VideoIcon fontSize="small" />,
            link: "video",
            parent: "content-management",
            show: isPermitted(PERMISSIONS.VIDEOS),
          },
          {
            title: "Gallery",
            icon: <CollectionsIcon fontSize="small" />,
            link: "gallery",
            parent: "content-management",
            show: isPermitted(PERMISSIONS.GALLERY),
          },
          {
            title: "Slidecasts",
            icon: <ModelTrainingIcon fontSize="small" />,
            link: "training",
            parent: "content-management",
            show: isPermitted(PERMISSIONS.TRAINING),
          },
          {
            title: "Know Your Ticks",
            icon: <PestControlIcon fontSize="small" />,
            link: "know-your-tick",
            parent: "content-management",
            show: isPermitted(PERMISSIONS.KNOW_YOUR_TICKS),
          },
        ],
      },
      {
        title: "Gamification",
        icon: <StairsIcon fontSize="small" />,
        link: "gamification",
        items: [
          {
            title: "Scorecard",
            icon: <TableChartIcon fontSize="small" />,
            link: "score",
            parent: "gamification",
            show: isPermitted(PERMISSIONS.SCORECARD),
          },
          {
            title: "Premium Scorecard",
            icon: <BackupTableRoundedIcon fontSize="small" />,
            link: "premium-scorecard",
            parent: "gamification",
            show: isPermitted(PERMISSIONS.REWARDS),
          },
        ],
      },
      {
        title: "Event Management",
        link: "event-management",
        icon: <CalendarMonthOutlinedIcon fontSize="small" />,
        items: [
          {
            title: "Events",
            icon: <CalendarMonthOutlinedIcon fontSize="small" />,
            link: "events",
            parent: "event-management",
            show: isPermitted(PERMISSIONS.EVENTS),
          },
          {
            title: "Events Request",
            icon: <CalendarMonthOutlinedIcon fontSize="small" />,
            link: "event-requests",
            parent: "event-management",
            show: isPermitted(PERMISSIONS.EVENTS),
          },
        ],
      },
      {
        title: "Product Management",
        link: "product-management",
        items: [
          {
            title: "Products",
            icon: <AddShoppingCartIcon fontSize="small" />,
            link: "products",
            // parent: "product-management",
            show: isPermitted(PERMISSIONS.PRODUCTS),
          },
        ],
      },

      {
        title: "Nearby Help",
        link: "nearBy",
        items: [
          {
            title: "Nearby Help",
            icon: <NearMeIcon fontSize="small" />, // Dashboard icon
            link: "nearBy",
            // parent: "nearBy",
            show: isPermitted(PERMISSIONS.NEAR_BY_HELP),
          },
        ],
      },

      {
        title: "Tick Map Management",
        link: "tick-map-management",
        items: [
          {
            title: "Tick Map Management",
            icon: <ScatterPlotIcon fontSize="small" />, // Dashboard icon
            link: "tick-map-management",
            // parent: "tick-map-management",
            show: isPermitted(PERMISSIONS.TICK_MAP),
          },
        ],
      },
      {
        title: "FAQs Management",
        link: "faqs",
        items: [
          {
            title: "FAQs Management",
            icon: <HelpOutlinedIcon fontSize="small" />, // Dashboard icon
            link: "faqs",
            // parent: "faqs",
            show: isPermitted(PERMISSIONS.FAQS),
          },
        ],
      },

      {
        title: "Sub Admins",
        link: "users",
        items: [
          {
            title: "Sub Admins",
            icon: <PersonIcon fontSize="small" />, // Dashboard icon
            link: "users",
            // parent: "users",
            show: isPermitted(PERMISSIONS.USER), // Always visible
          },
        ],
      },
      {
        title: "Terms & Conditions",
        link: "terms-and-condition",
        items: [
          {
            title: "Terms & Conditions",
            icon: <NoteAddIcon fontSize="small" />, // Dashboard icon
            link: "terms-and-condition",
            // parent: "terms-and-condition",
            show: isPermitted(PERMISSIONS.TERM_AND_CONDITION), // Always visible
          },
        ],
      },
      {
        title: "Privacy Policy",
        link: "data-policy",
        items: [
          {
            title: "Privacy Policy",
            icon: <PolicyIcon fontSize="small" />, // Dashboard icon
            link: "data-policy",
            // parent: "data-policy",
            show: isPermitted(PERMISSIONS.PRIVACY_POLICY), // Always visible
          },
        ],
      },
    ],
    [isPermitted] // Recompute when permissions change
  );
  const theme = useTheme();

  return (
    <>
      <Main
        sx={{
          p: 0,
        }}
        open={drawerOpen}
      >
        <DrawerHeader />
      </Main>
      <Drawer
        variant="persistent"
        // variant="permanent"
        open={drawerOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "#146879",
          },
        }}
        anchor="left"
      >
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#146879",
            borderBottom: "1px solid #298395",
            pt: 2,
            pb: 2.5,
            [theme.breakpoints.up("sm")]: {
              minHeight: "94px", // Override for min-width 600px
            },
          }}
        >
          <Box
            component="img"
            src="/img/elanco-logo.svg"
            alt="Elanco"
            sx={{
              height: 60,
              margin: "auto",
            }}
          />
          <IconButton
            onClick={() => {
              !!drawerOpen
                ? dispatch(setDrawerClose())
                : dispatch(setDrawerOpen());
            }}
          >
            <MenuOpenIcon
              fontSize="large"
              sx={{
                color: "#fff",
                p: 0,
                marginLeft: "auto",
              }}
            />
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px", // Width of the scrollbar
              height: "6px", // Height of the scrollbar for horizontal scrolling
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#146879", // Track color
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888", // Thumb color
              borderRadius: "4px", // Round corners of the thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555", // Thumb color on hover
            },
          }}
        >
          <List>
            {groupedList.map((group) =>
              group.items.filter((i) => i.show === true && !!i.parent).length >
              0 ? (
                <CollapsibleGroup
                  key={group.title}
                  group={group}
                  selectedLink={selectedLink}
                  onItemClick={handleItemClick}
                  isCollapsed={isCollapsed}
                  onToggle={toggleCollapse}
                  icon={group.icon}
                />
              ) : (
                group.items.map(
                  (item) =>
                    item.show === true && (
                      <MenuItem
                        key={item.link}
                        item={item}
                        selectedLink={selectedLink}
                        onItemClick={handleItemClick}
                        selected={true}
                      />
                    )
                )
              )
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default SideList;

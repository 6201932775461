// External imports
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import './style.scss';

// Internal imports
import FormValidation from '../common/FormValidations';
import { secuirtyCode } from '../../store/actions/auth';
import Spinner from '../layout/Spinner';

function SecuityCode() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state === null) {
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get('email');
      location.state = { email: email };
    }
  }, [location]);

  const formik = useFormik({
    initialValues: {
      token: '',
    },
    validationSchema: FormValidation.SecurityCodeSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      const code = values.token.toString();
      dispatch(secuirtyCode(code))
        .then(() => {
          navigate('/set-password', {
            state: { data: location, token: code },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        {/* Left: Security Code Form */}
        <Container
          maxWidth="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            padding: 4,
          }}
        >
          <Box sx={{ px: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Box
                  component="img"
                  src="/img/elanco-logo.svg"
                  alt="Elanco"
                  sx={{
                    height: '80px',
                    objectFit: 'cover',
                    mb: 3,
                  }}
                />
                <Typography
                  color="textPrimary"
                  variant="h4"
                  sx={{ mb: 1, fontSize: '1.5rem' }}
                >
                  Security Code
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Enter the security code you received on your registered email.
                </Typography>
              </Box>
              <TextField
                error={Boolean(formik.touched.token && formik.errors.token)}
                fullWidth
                helperText={formik.touched.token && formik.errors.token}
                label="Security Token"
                margin="normal"
                name="token"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="number"
                value={formik.values.token}
                variant="outlined"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric',
                  },
                  style: {
                    WebkitAppearance: 'none',
                    MozAppearance: 'textfield',
                  },
                }}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading && (
                    <span>
                      <CircularProgress size={24} sx={{ marginRight: 1 }} />
                    </span>
                  )}
                  <span>Continue</span>
                </Button>

                {message && (
                  <div className="form-group" style={{ paddingTop: '1em' }}>
                    <Alert severity="error">{message}</Alert>
                  </div>
                )}
              </Box>
            </form>
          </Box>
        </Container>

        {/* Right: Image */}
        <Box
          className="hero-img"
          sx={{
            flex: 1,
            backgroundImage: 'url(/img/elanco-admin-cattle.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: { xs: 'none', sm: 'block', md: 'block' },
          }}
        ></Box>
      </Box>
      <Spinner />
    </>
  );
}

export default SecuityCode;
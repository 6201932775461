import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import {
  CREATE_EVENT_FAILURE,
  CREATE_EVENT_SUCCESS,
  DELETE_EVENT_ATTACHMENT,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  EDIT_EVENT_SUCCESS,
  GET_EVENTS_DATA_FAIL,
  GET_EVENTS_DATA_SUCCESS,
  SET_EVENT_ATTACHMENT,
  TOGGLE_EVENT_ACTIVATION_FAILURE,
  TOGGLE_EVENT_ACTIVATION_SUCCESS,
} from "./type";

export const getEventsData = (queryParam) => async (dispatch) => {
  try {
    const res = await trackPromise(
      api.post("/event-management/list", queryParam)
    );

    dispatch({
      type: GET_EVENTS_DATA_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_EVENTS_DATA_FAIL, payload: err.message });
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const res = await trackPromise(api.delete(`/event-management/${id}`));
    dispatch({
      type: DELETE_EVENT_SUCCESS,
      payload: res.data,
    });
    return Promise.resolve();
  } catch (err) {
    dispatch({ type: DELETE_EVENT_FAILURE, payload: err.message });
  }
  return Promise.reject();
};

export const toggleEventActivation =
  (id = null, resource) =>
  async (dispatch) => {
    const temp =
      resource.status === "Published"
        ? "Draft"
        : "Published" && resource.status !== "Published"
        ? "Published"
        : null;
    try {
      const res = await trackPromise(
        api.patch(`/event-management/${id}`, {
          status: temp,
        })
      );
      dispatch({
        type: TOGGLE_EVENT_ACTIVATION_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: TOGGLE_EVENT_ACTIVATION_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };

export const editEvent = (id, record) => {
  const { language, ...event } = record;
  return async (dispatch) => {
    try {
      const res = await trackPromise(
        api.patch(`/event-management/${id}`, event)
      );
      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: res.data.data,
      });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: EDIT_EVENT_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

export const createEvent = (record) => {
  const { language, ...event } = record;
  return async (dispatch) => {
    try {
      const res = await trackPromise(api.post("/event-management", event));
      dispatch({
        type: CREATE_EVENT_SUCCESS,
        payload: res.data.data,
      });

      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: CREATE_EVENT_FAILURE,
        payload: err.message,
      });
      return Promise.reject();
    }
  };
};

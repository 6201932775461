import React from "react";
import Login from "../component/authentication/Login";
import ForgetPassword from "../component/authentication/ForgetPassword";
import ResetPassword from "../component/authentication/ResetPassword";
import SecuityCode from "../component/authentication/SecuityCode";
import ArticleList from "../component/articles/list-article/ArticleList";
import NewArticle from "../component/articles/create-article/NewArticle";
import VedioList from "../component/video/list-video/VedioList";
import GalleryList from "../component/gallery/list-gallery/GalleryList";
import TrainingList from "../component/training/training-list/TrainingList";
import Training from "../component/training/training/Training";
import Gallery from "../component/gallery/gallery/Gallery";
import Video from "../component/video/video/Video";
import KYTList from "../component/know-your-ticks/Listing/KYTList";
import Tick from "../component/know-your-ticks/Editing/Tick";
import EventListing from "../component/events/listing/EventListing";
import EventForm from "../component/events/EventForm";
import ProductListing from "../component/product/Listing/ProductListing";
import Product from "../component/product/AddProduct/Product";
import ScoreCard from "../component/scoreCard/ScoreCard";
import NearByList from "../component/NearBy/NearByList/NearByList";
import NewNearBy from "../component/NearBy/NearByList/AddNewNearBy/NewNearBy";
import HelpDeleteAccount from "../component/HelpPageForm/HelpingPage";
import DeleteConfirmationPage from "../component/delete-confirmation/index";

import {
  ARTICLE_URL,
  CHART_URL,
  NEARBY_URL,
  NEW_NEARBY_URL,
  DATAPOLICY_URL,
  EDIT_ARTICLE_URL,
  EDIT_EVENT_URL,
  EDIT_GALLERY_URL,
  EDIT_PRODUCT_URL,
  EDIT_TICK_URL,
  EDIT_TRAINING_URL,
  EDIT_VIDEO_URL,
  EVENT_URL,
  FORGET_PASSWORD_URL,
  GALLERY_URL,
  LOGIN_URL,
  NEW_ARTICLE_URL,
  NEW_EVENT_URL,
  EDIT_NEARBY_URL,
  NEW_GALLERY_URL,
  NEW_PRODUCT_URL,
  NEW_TRAINING_URL,
  NEW_VIDEO_URL,
  PRODUCT_URL,
  RESET_PASSWORD_URL,
  SCORE_URL,
  SECURITY_CODE_URL,
  TERM_AND_CONDITION_URL,
  TICK_URL,
  TRAINING_URL,
  VIDEO_URL,
  CONTACTUS_URL,
  NEARBYHELP_BULKIMPORT_URL,
  HELP_URL,
  CONFIRM_DELETION,
  TICK_MAP_URL,
  PREMIUM_SCORE_URL,
  SETUP_PASSWORD_URL,
  VERIFY_OTP_URL,
  EVENT_REQUEST_URL,
  VIEW_EVENT_URL,
} from "../constants/apiUrls";
import MixpanelChart from "../component/dashboard/MixpanelChart";
import TermAndCondition from "../component/termCondition";
import DataPolicy from "../component/dataPrivacy";
import ContactUs from "../component/contact-us/index";
import BulkImport from "../component/NearBy/NearByList/BulkImport";
import { ContentPasteOffRounded } from "@mui/icons-material";
import TickMap from "../component/TickMap";
import TickMapManagement from "../pages/TickMapManagement";
import { URL } from "../constants/URL";
import { PERMISSIONS } from "../constants/Permissions";
import FAQList from "../pages/FAQs/list";
import AddFaqs from "../pages/FAQs/form";

import UserList from "../pages/Users/list";
import AddUser from "../pages/Users/form";
import SetupPassword from "../component/authentication/setupPassword";
import VerifyOTP from "../component/authentication/VerifyOTP";
import EventRequest from "../component/events/requests/EventRequest";
import EventRequestListing from "../pages/EventRequests/list";

const AppRoutes = [
  {
    name: "Login",
    path: LOGIN_URL,
    component: <Login />,
    requireAuthentication: false,
  },
  {
    name: "Forget Password",
    path: FORGET_PASSWORD_URL,
    component: <ForgetPassword />,
    requireAuthentication: false,
  },
  {
    name: "ResetPassword",
    path: RESET_PASSWORD_URL,
    component: <ResetPassword />,
    requireAuthentication: false,
  },
  {
    name: "SecuityCode",
    path: SECURITY_CODE_URL,
    component: <SecuityCode />,
    requireAuthentication: false,
  },
  {
    name: "VerifyCode",
    path: VERIFY_OTP_URL,
    component: <VerifyOTP />,
    requireAuthentication: false,
  },
  {
    name: "SetupPassword",
    path: SETUP_PASSWORD_URL,
    component: <SetupPassword />,
    requireAuthentication: false,
  },
  {
    name: "Article",
    path: ARTICLE_URL,
    component: <ArticleList />,
    requireAuthentication: true,
    slug: PERMISSIONS.ARTICLES,
  },
  {
    name: "New Article",
    path: NEW_ARTICLE_URL,
    component: <NewArticle />,
    requireAuthentication: true,
    slug: PERMISSIONS.ARTICLES,
  },
  {
    name: "Edit Article",
    path: EDIT_ARTICLE_URL,
    component: <NewArticle />,
    requireAuthentication: true,
    slug: PERMISSIONS.ARTICLES,
  },
  {
    name: "Video",
    path: VIDEO_URL,
    component: <VedioList />,
    requireAuthentication: true,
    slug: PERMISSIONS.VIDEOS,
  },
  {
    name: "Edit Video",
    path: EDIT_VIDEO_URL,
    component: <Video />,
    requireAuthentication: true,
    slug: PERMISSIONS.VIDEOS,
  },
  {
    name: "New Video",
    path: NEW_VIDEO_URL,
    component: <Video />,
    requireAuthentication: true,
    slug: PERMISSIONS.VIDEOS,
  },
  {
    name: "Training",
    path: TRAINING_URL,
    component: <TrainingList />,
    requireAuthentication: true,
    slug: PERMISSIONS.TRAINING,
  },
  {
    name: "New Training",
    path: NEW_TRAINING_URL,
    component: <Training />,
    requireAuthentication: true,
    slug: PERMISSIONS.TRAINING,
  },
  {
    name: "Edit Training",
    path: EDIT_TRAINING_URL,
    component: <Training />,
    requireAuthentication: true,
    slug: PERMISSIONS.TRAINING,
  },
  {
    name: "Gallery",
    path: GALLERY_URL,
    component: <GalleryList />,
    requireAuthentication: true,
    slug: PERMISSIONS.GALLERY,
  },
  {
    name: "New Gallery",
    path: NEW_GALLERY_URL,
    component: <Gallery />,
    requireAuthentication: true,
    slug: PERMISSIONS.GALLERY,
  },
  {
    name: "Edit Gallery",
    path: EDIT_GALLERY_URL,
    component: <Gallery />,
    requireAuthentication: true,
    slug: PERMISSIONS.GALLERY,
  },
  {
    name: "Know Your Ticks",
    path: TICK_URL,
    component: <KYTList />,
    requireAuthentication: true,
    slug: PERMISSIONS.KNOW_YOUR_TICKS,
  },
  {
    name: "Edit Know Your Tick",
    path: EDIT_TICK_URL,
    component: <Tick />,
    requireAuthentication: true,
    slug: PERMISSIONS.KNOW_YOUR_TICKS,
  },
  {
    name: "Events",
    path: EVENT_URL,
    component: <EventListing />,
    requireAuthentication: true,
    slug: PERMISSIONS.EVENTS,
  },
  {
    name: "New Event",
    path: NEW_EVENT_URL,
    component: <EventForm />,
    requireAuthentication: true,
    slug: PERMISSIONS.EVENTS,
  },
  {
    name: "Edit event",
    path: EDIT_EVENT_URL,
    component: <EventForm />,
    requireAuthentication: true,
    slug: PERMISSIONS.EVENTS,
  },
  {
    name: "Event Requests",
    path: EVENT_REQUEST_URL,
    component: <EventRequestListing />,
    requireAuthentication: true,
    slug: PERMISSIONS.EVENTS,
  },
  {
    name: "Event View",
    path: VIEW_EVENT_URL,
    component: <EventRequest />,
    requireAuthentication: true,
    slug: PERMISSIONS.EVENTS,
  },
  {
    name: "Product",
    path: PRODUCT_URL,
    component: <ProductListing />,
    requireAuthentication: true,
    slug: PERMISSIONS.PRODUCTS,
  },
  {
    name: "New Product",
    path: NEW_PRODUCT_URL,
    component: <Product />,
    requireAuthentication: true,
    slug: PERMISSIONS.PRODUCTS,
  },
  {
    name: "Edit Product",
    path: EDIT_PRODUCT_URL,
    component: <Product />,
    requireAuthentication: true,
    slug: PERMISSIONS.PRODUCTS,
  },
  {
    name: "Scorecard",
    path: SCORE_URL,
    component: <ScoreCard />,
    requireAuthentication: true,
    slug: PERMISSIONS.SCORECARD,
  },
  {
    name: "Premium ScoreCard",
    path: PREMIUM_SCORE_URL,
    component: <ScoreCard />,
    requireAuthentication: true,
    slug: PERMISSIONS.REWARDS,
  },
  {
    name: "Chart",
    path: CHART_URL,
    component: <MixpanelChart />,
    requireAuthentication: true,
  },
  {
    name: "Terms and Condition",
    path: TERM_AND_CONDITION_URL,
    component: <TermAndCondition />,
    requireAuthentication: true,
    slug: PERMISSIONS.TERM_AND_CONDITION,
  },
  {
    name: "Data Policy",
    path: DATAPOLICY_URL,
    component: <DataPolicy />,
    requireAuthentication: true,
    slug: PERMISSIONS.PRIVACY_POLICY,
  },
  {
    name: "Contact Us",
    path: CONTACTUS_URL,
    component: <ContactUs />,
    requireAuthentication: true,
  },
  {
    name: "Near By",
    path: NEARBY_URL,
    component: <NearByList />,
    requireAuthentication: true,
    slug: PERMISSIONS.NEAR_BY_HELP,
  },
  {
    name: "New Nearby",
    path: NEW_NEARBY_URL,
    component: <NewNearBy />,
    requireAuthentication: true,
    slug: PERMISSIONS.NEAR_BY_HELP,
  },
  {
    name: "Edit Nearby",
    path: EDIT_NEARBY_URL,
    component: <NewNearBy />,
    requireAuthentication: true,
    slug: PERMISSIONS.NEAR_BY_HELP,
  },
  {
    name: "NearbyHelp Bulk-Import",
    path: NEARBYHELP_BULKIMPORT_URL,
    component: <BulkImport />,
    requireAuthentication: true,
    slug: PERMISSIONS.NEAR_BY_HELP,
  },
  {
    name: "Help",
    path: HELP_URL,
    component: <HelpDeleteAccount />,
    requireAuthentication: false,
  },
  {
    name: "Delete Confirmation",
    path: CONFIRM_DELETION,
    component: <DeleteConfirmationPage />,
    requireAuthentication: false,
  },
  {
    name: "Tick Map Management",
    path: TICK_MAP_URL,
    component: <TickMapManagement />,
    requireAuthentication: true,
    slug: PERMISSIONS.TICK_MAP,
  },
  {
    name: "FAQs Management",
    path: URL.FAQS,
    component: <FAQList />,
    requireAuthentication: true,
    slug: PERMISSIONS.FAQS,
  },
  {
    name: "Add Faq",
    path: URL.FAQS_ADD,
    component: <AddFaqs />,
    requireAuthentication: true,
    slug: PERMISSIONS.FAQS,
  },
  {
    name: "Edit Faq",
    path: URL.FAQS_EDIT,
    component: <AddFaqs />,
    requireAuthentication: true,
    slug: PERMISSIONS.FAQS,
  },
  {
    name: "User Management",
    path: URL.USERS,
    component: <UserList />,
    requireAuthentication: true,
    slug: PERMISSIONS.USER,
  },
  {
    name: "Add User",
    path: URL.USER_ADD,
    component: <AddUser />,
    requireAuthentication: true,
    slug: PERMISSIONS.USER,
  },
  {
    name: "Edit User",
    path: URL.USER_EDIT,
    component: <AddUser />,
    requireAuthentication: true,
    slug: PERMISSIONS.USER,
  },
];

export default AppRoutes;

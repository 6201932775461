import React from "react";
import { TextField, FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setTitle } from "../../../store/actions/currentProduct";
import { localizedProductTitleSelector } from "../../../store/reducers/ProductSelector";
import ProductButton from "./ProductButton";

const ProductHeader = () => {
  const dispatch = useDispatch();
  // const [lang, setLang] = useState('EN');
  const language = useSelector((state) => state.currentProduct.language);
  const productTitle = useSelector(localizedProductTitleSelector);

  const handleLanguage = (event) => {
    const language = event.target.value;
    dispatch(setLanguage(language));
  };
  const handleTitleChange = (e) => {
    const text = e.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setTitle(trimmedText));
  };

  return (
      <>
        <Box
          sx={{
              display: "flex",
              mb: 1.5,
              pt: 0.75,
              pb: 2,
              gap: 1,
              borderBottom: '1px solid #E1E1E1',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
          <Typography variant="h5">
            Add Product
          </Typography>

            <Box
              sx={{
                display: "flex",
                width: '100%',
                gap: "12px",
                '@media (max-width: 820px)': {
                  flexGrow: 1,
                  justifyContent: "end",          
                },
                '@media (max-width: 767px)': {
                  flexWrap: "wrap",
                  gap: '12px',         
                },
              }}
            >

          <TextField
            fullWidth
            id="title"
            size="small"
            className="title"
            required
            value={productTitle || ""}
            variant="outlined"
            onChange={handleTitleChange}
            placeholder={`Enter title for your product.`}
          />
          <FormControl
            size="small"
            className="selector"
            sx={{ 
              minWidth: "10rem",
              '@media (max-width: 767px)': {
                minWidth: "100%", 
              },
            }}
          >
            <Select
              onChange={handleLanguage}
              value={language}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>

          </Box>
        </Box>
        {/* <ProductButton></ProductButton> */}
      </>
  );
};

export default ProductHeader;

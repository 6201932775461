import React, { useEffect, useState } from "react";
import { WithHeader } from "../common/WithHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrganizor,
  getOrganizor,
  updateOrganizer,
} from "../../store/actions/organizor";
import { getFilteredOrganizor } from "../../store/reducers/selector";
import {
  Button,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Box,
  Avatar,
} from "@mui/material";
import { Stack } from "@mui/system";
import { setOrganizorId } from "../../store/actions/currentEvent";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";
import BlobService from "../../services/blob-service";

const Organizer = () => {
  const dispatch = useDispatch();
  const [organizorfullName, setOrganizorFullName] = useState("");
  const [organizorDetail, setOrganizorDetail] = useState("");
  const [errorFullname, setErrorFullname] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);
  const selectedOrganizor = useSelector(
    (state) => state.currentEvent.organizerId.id
  );
  const filteredOrganizor = useSelector(getFilteredOrganizor);
  const languageSelector = useSelector((state) => state.currentEvent.language);
  const authorUpdated = useSelector(
    (state) => state.organizorData.organizorUpdated
  );

  const externalOrganizer = useSelector(
    (state) => state.currentEvent.externalOrganizer
  );

  useEffect(() => {
    dispatch(getOrganizor());
    if (externalOrganizer) {
      setOrganizorFullName(externalOrganizer.name);
      setOrganizorDetail(externalOrganizer.url);
    }
  }, [dispatch, authorUpdated]);

  const handleSaveButton = () => {
    if (!organizorfullName.trim()) {
      setErrorFullname(true);
      return;
    }
    if (!organizorDetail.trim()) {
      setErrorDetails(true);
      return;
    }
    const org = {
      fullname: {
        [LANGUAGE_KEY.ENGLISH]: organizorfullName.trim(),
        [LANGUAGE_KEY.LUGANDA]: organizorfullName.trim(),
        [LANGUAGE_KEY.RUNYAKITARA]: organizorfullName.trim(),
      },
      details: {
        [LANGUAGE_KEY.ENGLISH]: organizorDetail.trim(),
        [LANGUAGE_KEY.LUGANDA]: organizorDetail.trim(),
        [LANGUAGE_KEY.RUNYAKITARA]: organizorDetail.trim(),
      },
    };
    dispatch(createOrganizor(org));
    setOrganizorFullName("");
    setOrganizorDetail("");
  };
  const handleOrganizorChange = (event) => {
    const optionId = event.target.value;
    dispatch(setOrganizorId(optionId));
  };

  const uploadProfilePic = (organizer, file) => {
    const filename = BlobService.generateFileName(file);
    const fileSize = file.size / 1024 / 1024; // convert to MB
    if (fileSize > 20) {
      toast.error(`Image size exceeds 20 MB limit`);
      return;
    }
    BlobService.uploadImage(filename, file).then(() => {
      dispatch(updateOrganizer(organizer, filename));
      dispatch(getOrganizor());
    });
  };

  const removeProfilePic = (organizer) => {
    BlobService.deleteImage(organizer.image).then(() => {
      dispatch(updateOrganizer(organizer, ""));
      dispatch(getOrganizor());
    });
  };

  const getAuthorRow = (item) => {
    const fullname = item.fullname[languageSelector];
    const details = item.details[languageSelector];
    const [firstName, lastName] =
      item?.fullname?.[languageSelector]?.split(" ");
    let initials;

    if (lastName) {
      initials =
        firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    } else {
      initials = firstName.substring(0, 2).toUpperCase();
    }
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          key={item.id}
          alignItems="center"
          direction="row"
          sx={{ p: "4px 0" }}
        >
          <FormControlLabel
            sx={{
              m: 0,
            }}
            key={`${item.id}-FormControlLabel`}
            value={item.id}
            control={<Radio key={item.id} />}
          />
          <Avatar
            alt={fullname}
            sx={{ width: 40, height: 40, fontSize: "14px" }}
            src={BlobService.getImageUri(item.image)}
          >
            {initials}
          </Avatar>
          <CardHeader
            sx={{
              p: 0,
              ml: 1,
            }}
            titleTypographyProps={{
              fontSize: "14px", // Adjusts the font size of the MuiTypography-root
            }}
            title={fullname}
            key={`${item.id}-CardHeader`}
          />
          <a
            style={{ fontSize: "14px", marginLeft: "5px" }}
            href={`${details}`}
          >
            {details}
          </a>
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
          <Button
            color="primary"
            size="sm"
            aria-label="upload picture"
            component="label"
            className="authorUpload"
            // disabled={!author[0]}
          >
            Upload Image
            <input
              id="upload-image"
              hidden
              accept=".jpeg, .png, .jpg"
              type="file"
              onChange={(e) => {
                uploadProfilePic(item, e.target.files[0]);
                e.target.value = "";
              }}
            />
          </Button>
          <Button
            color="primary"
            aria-label="remove picture"
            component="label"
            className="authorUpload"
            disabled={!item.image}
            onClick={(e) => removeProfilePic(item)}
          >
            Remove Image
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        overflowY: "auto",
      }}
    >
      <RadioGroup value={selectedOrganizor} onChange={handleOrganizorChange}>
        {filteredOrganizor.length > 0 &&
          filteredOrganizor.map((item) => getAuthorRow(item))}
      </RadioGroup>
      <Box sx={{ display: "flex", p: 2, gap: 1, flexWrap: "wrap" }}>
        <TextField
          fullWidth
          type="text"
          value={organizorfullName}
          onChange={(e) => {
            setOrganizorFullName(e.target.value);
            setErrorFullname(false);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              // borderRadius: 0, // set borderRadius to 0
              // width: "27em",
              // marginRight: "0.5em",
              // marginLeft: "0.2em",
            },
          }}
          placeholder="Enter the name of the organizer here."
          error={errorFullname}
          helperText={errorFullname && "Fullname is required"}
        />
        <TextField
          fullWidth
          type="text"
          value={organizorDetail}
          onChange={(e) => {
            setOrganizorDetail(e.target.value);
            setErrorDetails(false);
          }}
          placeholder="Enter the details or link to the organizer here.."
          error={errorDetails}
          helperText={errorDetails && "Details is required"}
        />
        <Button
          variant="contained"
          size="small"
          onClick={handleSaveButton}
          sx={{
            "@media (max-width: 767px)": {
              width: "100%",
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default WithHeader(Organizer, "Organizer *");

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { WithHeader } from "../common/WithHeader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  setEventEndDate,
  setEventStartDate,
} from "../../store/actions/currentEvent";
import { Stack, Box, Grid } from "@mui/material";
import {
  eventEndDateSelector,
  eventStartDateSelector,
} from "../../store/reducers/selector";

dayjs.extend(utc);

const EventDate = ({ setDateError }) => {
  const dispatch = useDispatch();
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [error, setError] = useState("");
  const startEvent = useSelector(eventStartDateSelector);
  const endEvent = useSelector(eventEndDateSelector);
  const startdateObj = dayjs.utc(startEvent).local();
  const enddateObj = dayjs.utc(endEvent).local();

  const handleStartDateChange = (date) => {
    const updatedStart = dayjs(date);
    setStartDateTime(updatedStart);
    dispatch(
      setEventStartDate(updatedStart.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    );
    validateDate(updatedStart.valueOf(), endDateTime?.valueOf());
  };

  const handleStartTimeChange = (time) => {
    const updatedStart = startdateObj
      .set("hour", time.hour())
      .set("minute", time.minute());
    setStartDateTime(updatedStart);
    dispatch(
      setEventStartDate(updatedStart.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    );
    validateDate(updatedStart.valueOf(), endDateTime?.valueOf());
  };

  const handleEndDateChange = (date) => {
    const updatedEnd = dayjs(date);
    setEndDateTime(updatedEnd);
    dispatch(
      setEventEndDate(updatedEnd.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    );
    validateDate(startDateTime?.valueOf(), updatedEnd.valueOf());
  };

  const handleEndTimeChange = (time) => {
    const updatedEnd = enddateObj
      .set("hour", time.hour())
      .set("minute", time.minute());
    setEndDateTime(updatedEnd);
    dispatch(
      setEventEndDate(updatedEnd.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    );
    validateDate(startDateTime?.valueOf(), updatedEnd.valueOf());
  };

  const validateDate = (start, end) => {
    if (start >= end) {
      setError("Invalid End Date");
      setDateError("Invalid");
    } else {
      setError("");
      setDateError("");
    }
  };

  return (
    <Box sx={{ 
      p: 2,
      display: "flex",
      flexWrap: "wrap",
      gap: 2, 
    }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} alignItems="center">
          {/* Start Date and Time */}
          <Grid item xs={12} sm={5}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Start Date"
                value={startdateObj}
                disablePast
                onChange={handleStartDateChange}
              />
            </DemoContainer>
          </Grid>
          <Grid item xs={12} sm={5}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Start Time"
                value={startdateObj}
                onChange={handleStartTimeChange}

              />
            </DemoContainer>
          </Grid>
          <Grid item xs={12} sm={2}>
            {" "}
            <span
              style={{
                backgroundColor: "#f0f0f0", // Light gray background
                color: "black", // Black text
                padding: "4px 8px", // Add padding for better readability
                borderRadius: "4px", // Optional: rounded corners
                display: "inline-block", // Ensure proper spacing
              }}
            >
              Uganda Time (GMT +3)
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          {/* End Date and Time */}
          <Grid item xs={12} sm={5}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="End Date"
                value={enddateObj}
                disablePast
                onChange={handleEndDateChange}
              />
            </DemoContainer>
          </Grid>
          <Grid item xs={12} sm={5}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="End Time"
                value={enddateObj}
                onChange={handleEndTimeChange}
              />
            </DemoContainer>
          </Grid>
          <Grid item xs={12} sm={2}>
            {" "}
            <span
              style={{
                backgroundColor: "#f0f0f0", // Light gray background
                color: "black", // Black text
                padding: "4px 8px", // Add padding for better readability
                borderRadius: "4px", // Optional: rounded corners
                display: "inline-block", // Ensure proper spacing
              }}
            >
              Uganda Time (GMT +3)
            </span>
          </Grid>
        </Grid>
      </LocalizationProvider>
      {error && <div style={{ color: "red" }}>{error}</div>}
    </Box>
  );
};

export default WithHeader(EventDate, "Event Date & Time *");

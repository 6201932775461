export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";

export const PERMISSIONS_SUCCESS = "PERMISSIONS_SUCCESS";
export const PERMISSIONS_FAIL = "PERMISSIONS_FAIL";

export const SECURITY_CODE_SUCCESS = "SECURITY_CODE_SUCCESS";
export const SECURITY_CODE_FAIL = "SECURITY_CODE_FAIL";

export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

export const EXPIRY_TOKEN_SUCCESS = "EXPIRY_TOKEN_SUCCESS";
export const EXPIRY_TOKEN_FAIL = "EXPIRY_TOKEN_FAIL";

// Categories
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";

export const SELECTED_CATEGORIES = "SELECTED_CATEGORIES";

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_LISTING_REQUEST = "GET_LISTING_REQUEST";
export const GET_LISTING_SUCCESS = "GET_LISTING_SUCCESS";
export const GET_LISTING_FAIL = "GET_LISTING_FAIL";
export const GET_RECOMMENDATION_SUCCESS = "GET_RECOMMENDATION_SUCCESS";
// author Deatils
export const CREATE_AUTHOR_REQUEST = "CREATE_AUTHOR_REQUEST";
export const CREATE_AUTHOR_SUCCESS = "CREATE_AUTHOR_SUCCESS";
export const CREATE_AUTHOR_FAILURE = "CREATE_AUTHOR_FAILURE";

export const UPDATE_AUTHOR_REQUEST = "UPDATE_AUTHOR_REQUEST";
export const UPDATE_AUTHOR_SUCCESS = "UPDATE_AUTHOR_SUCCESS";
export const UPDATE_AUTHOR_FAILURE = "UPDATE_AUTHOR_FAILURE";

export const GET_AUTHOR_REQUEST = "GET_AUTHOR_REQUEST";
export const GET_AUTHOR_SUCCESS = "GET_AUTHOR_SUCCESS";
export const GET_AUTHOR_FAILURE = "GET_AUTHOR_FAILURE";
export const SELECTED_AUTHOR = "SELECTED_AUTHOR";

// publisher Details
export const CREATE_PUBLISHER_REQUEST = "CREATE_PUBLISHER_REQUEST";
export const CREATE_PUBLISHER_SUCCESS = "CREATE_PUBLISHER_SUCCESS";
export const CREATE_PUBLISHER_FAILURE = "CREATE_PUBLISHER_FAILURE";

export const UPDATE_PUBLISHER_REQUEST = "UPDATE_PUBLISHER_REQUEST";
export const UPDATE_PUBLISHER_SUCCESS = "UPDATE_PUBLISHER_SUCCESS";
export const UPDATE_PUBLISHER_FAILURE = "UPDATE_PUBLISHER_FAILURE";

export const GET_PUBLISHER_REQUEST = "GET_PUBLISHER_REQUEST";
export const GET_PUBLISHER_SUCCESS = "GET_PUBLISHER_SUCCESS";
export const GET_PUBLISHER_FAILURE = "GET_PUBLISHER_FAILURE";

export const SELECTED_PUBLISHER = "SELECTED_PUBLISHER";

// Learning Resource
export const CREATE_LEARNING_RESOURCE_REQUEST =
  "CREATE_LEARNING_RESOURCE_REQUEST";
export const CREATE_LEARNING_RESOURCE_SUCCESS =
  "CREATE_LEARNING_RESOURCE_SUCCESS";
export const CREATE_LEARNING_RESOURCE_FAIL = "CREATE_LEARNING_RESOURCE_FAIL";

export const EDIT_LEARNING_RESOURCE_REQUEST = "EDIT_LEARNING_RESOURCE_REQUEST";
export const EDIT_LEARNING_RESOURCE_SUCCESS = "EDIT_LEARNING_RESOURCE_SUCCESS";
export const EDIT_LEARNING_RESOURCE_FAIL = "EDIT_LEARNING_RESOURCE_FAIL";

export const TOGGLE_GATING_CONTENT_REQUEST = "TOGGLE_GATING_CONTENT_REQUEST";
export const TOGGLE_GATING_CONTENT_SUCCESS = "TOGGLE_GATING_CONTENT_SUCCESS";
export const TOGGLE_GATING_CONTENT_FAILURE = "TOGGLE_GATING_CONTENT_FAILURE";

export const TOGGLE_GATING_CONTENT_REQUEST_ALL =
  "TOGGLE_GATING_CONTENT_REQUEST_ALL";
export const TOGGLE_GATING_CONTENT_SUCCESS_ALL =
  "TOGGLE_GATING_CONTENT_SUCCESS_ALL";
export const TOGGLE_GATING_CONTENT_FAILURE_ALL =
  "TOGGLE_GATING_CONTENT_FAILURE_ALL";

export const TOGGLE_ACTIVATION_CONTENT_REQUEST =
  "TOGGLE_ACTIVATION_CONTENT_REQUEST";
export const TOGGLE_ACTIVATION_CONTENT_SUCCESS =
  "TOGGLE_ACTIVATION_CONTENT_SUCCESS";
export const TOGGLE_ACTIVATION_CONTENT_FAILURE =
  "TOGGLE_ACTIVATION_CONTENT_FAILURE";

export const TOGGLE_MARK_PREMIUM_REQUEST = "TOGGLE_MARK_PREMIUM_REQUEST";
export const TOGGLE_MARK_PREMIUM_SUCCESS = "TOGGLE_MARK_PREMIUM_SUCCESS";
export const TOGGLE_MARK_PREMIUM_FAILURE = "TOGGLE_MARK_PREMIUM_FAILURE";

export const DELETE_LEARING_RESOURCE_REQUEST =
  "DELETE_LEARING_RESOURCE_REQUEST";
export const DELETE_LEARING_RESOURCE_SUCCESS =
  "DELETE_LEARING_RESOURCE_SUCCESS";
export const DELETE_LEARING_RESOURCE_FAILURE =
  "DELETE_LEARING_RESOURCE_FAILURE";

export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAIL = "DELETE_RECORD_FAIL";

//current resource
export const RETRIEVE_EDIT_RESOURCE_SUCCESS = "RETRIEVE_EDIT_RESOURCE_SUCCESS";
export const RETRIEVE_EDIT_RESOURCE_FAIL = "RETRIEVE_EDIT_RESOURCE_FAIL";
export const SET_READING_TIME = "SET_READING_TIME";
export const SET_READING_SCORE = "SET_READING_SCORE";
export const SET_COVER_IMAGE = "SET_COVER_IMAGE";
export const SET_THUMBNAIL = "SET_THUMBNAIL";
export const SET_VEDIO_CONTENT = "SET_VEDIO_CONTENT";
export const SET_VIDEO_URL = "SET_VIDEO_URL";
export const SET_TITLE = "SET_TITLE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_CONTENT = "SET_CONTENT";
export const TOGGLE_CATEGORY = "SET_CATEGORIES";
export const TOGGLE_RECOMMENDATION = "TOGGLE_RECOMMENDATION";
export const SET_RESOURCE_TYPE = "SET_RESOURCE_TYPE";
export const SET_TICK_IMAGE = "SET_TICK_IMAGE";
export const SET_LOCAL_VALUE = "SET_LOCAL_VALUE";
export const SET_SCIENTIFIC_VALUE = "SET_SCIENTIFIC_VALUE";
export const DELETE_TICK = "DELETE_TICK";
export const SET_HTML_IMAGE = "SET_HTML_IMAGE";
export const SET_DISTRICT = "SET_DISTRICT";
export const SET_PHONE = "SET_PHONE";
export const SET_INCHARGE = "SET_INCHARGE";
export const SET_FULL_NAME = "SET_FULLNAME";
export const SET_SPECIES = "SET_SPECIES";
export const SET_CONTENT_SPECIES = "SET_CONTENT_SPECIES";

// current Event
export const SET_EVENT_DETAIL = "SET_EVENT_DETAIL";
export const SET_EVENT_TITLE = "SET_EVENT_TITLE";
export const SET_EVENT_COVER_IMAGE = "SET_EVENT_COVER_IMAGE";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_TARGET_AUDIENCE = "SET_TARGET_AUDIENCE";
export const TOGGLE_EVENT_CATEGORY = "TOGGLE_EVENT_CATEGORY";
export const SET_EVENT_LANGUAGE = "SET_EVENT_LANGUAGE";
export const SET_EVENT_START_DATE = "SET_EVENT_START_DATE";
export const SET_ORGANIZOR = "SET_ORGANIZOR";
export const SET_EVENT_END_DATE = "SET_EVENT_END_DATE";
export const RETRIEVE_EVENT_SUCCESS = "RETRIEVE_EVENT_SUCCESS";
export const RETRIEVE_EVENT_FAILURE = "RETRIEVE_EVENT_FAILURE";
export const SET_EVENT_DETAIL_LANGUAGE = "SET_EVENT_DETAIL_LANGUAGE";
export const SET_EVENT_LABEL = "SET_EVENT_LABEL";
export const SET_EVENT_LINK = "SET_EVENT_LINK";
export const SET_EVENT_NOTIFICATION_DATE = "SET_EVENT_NOTIFICATION_DATE";
export const SET_EVENT_NOTIFICATION_TIME = "SET_EVENT_NOTIFICATION_TIME";
export const SET_EVENT_NOTIFICATION_FREQUENCY =
  "SET_EVENT_NOTIFICATION_FREQUENCY";
export const SET_EVENT_NOTIFICATION_INTERVAL =
  "SET_EVENT_NOTIFICATION_INTERVAL";
// current Product
export const SET_PRODUCT_TITLE = "SET_PRODUCT_TITLE";
export const SET_PRODUCT_LANGUAGE = "SET_PRODUCT_LANGUAGE";
export const SET_PRODUCT_COVER_IMAGE = "SET_PRODUCT_COVER_IMAGE";
export const SET_PRODUCT_ABOUT = "SET_PRODUCT_ABOUT";
export const SET_PRODUCT_APPLICATION = "SET_PRODUCT_APPLICATION";
export const SET_PRODUCT_DOSAGE = "SET_PRODUCT_DOSAGE";
export const TOGGLE_PRODUCT_SPECIES = "TOGGLE_PRODUCT_SPECIES";
export const RETRIEVE_PRODUCT_SUCCESS = "RETRIEVE_PRODUCT_SUCCESS";
export const RETRIEVE_PRODUCT_FAILURE = "RETRIEVE_PRODUCT_FAILURE";
export const SET_PRODUCT_ATTACHMENT = "SET_PRODUCT_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const SET_PRODUCT_HTML_IMAGE = "SET_PRODUCT_HTML_IMAGE";

// event category
export const GET_EVENT_CATEGORIES_SUCCESS = "GET_EVENT_CATEGORIES_SUCCESS";
export const GET_EVENT_CATEGORIES_FAILURE = "GET_EVENT_CATEGORIES_FAILURE";
export const CREATE_EVENT_CATEGORY_SUCCESS = "CREATE_EVENT_CATEGORY_SUCCESS";
export const CREATE_EVENT_CATEGORY_FAILURE = "CREATE_EVENT_CATEGORY_FAILURE";

// events
export const GET_EVENTS_DATA_SUCCESS = "GET_EVENTS_DATA_SUCCESS";
export const GET_EVENTS_DATA_FAIL = "GET_EVENTS_DATA_FAIL";
export const TOGGLE_EVENT_ACTIVATION_SUCCESS =
  "TOGGLE_EVENT_ACTIVATION_SUCCESS";
export const TOGGLE_EVENT_ACTIVATION_FAILURE =
  "TOGGLE_EVENT_ACTIVATION_FAILURE";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAILURE = "EDIT_EVENT_FAILURE";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";
export const DELETE_EVENT_CATEGORY_REQUEST = "DELETE_EVENT_CATEGORY_REQUEST";
export const DELETE_EVENT_CATEGORY_FAILURE = "DELETE_EVENT_CATEGORY_FAILURE";
export const DELETE_EVENT_CATEGORY_SUCCESS = "DELETE_EVENT_CATEGORY_SUCCESS";
export const SET_EVENT_ATTACHMENT = "SET_EVENT_ATTACHMENT";
export const DELETE_EVENT_ATTACHMENT = "DELETE_EVENT_ATTACHMENT";
// product
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";
export const TOGGLE_PRODUCT_ACTIVATION_SUCCESS =
  "TOGGLE_PRODUCT_ACTIVATION_SUCCESS";
export const TOGGLE_PRODUCT_ACTIVATION_FAILURE =
  "TOGGLE_PRODUCT_ACTIVATION_FAILURE";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE";

// organizor Details
export const GET_ORGANZIOR_REQUEST = "GET_ORGANZIOR_REQUEST";
export const GET_ORGANZIOR_SUCCESS = "GET_ORGANZIOR_SUCCESS";
export const GET_ORGANZIOR_FAILURE = "GET_ORGANZIOR_FAILURE";

export const CREATE_ORGANZIOR_REQUEST = "CREATE_ORGANZIOR_REQUEST";
export const CREATE_ORGANZIOR_SUCCESS = "CREATE_ORGANZIOR_SUCCESS";
export const CREATE_ORGANZIOR_FAILURE = "CREATE_ORGANZIOR_FAILURE";

export const UPDATE_ORGANIZER_REQUEST = "UPDATE_ORGANIZER_REQUEST";
export const UPDATE_ORGANIZER_SUCCESS = "UPDATE_ORGANIZER_SUCCESS";
export const UPDATE_ORGANIZER_FAILURE = "UPDATE_ORGANIZER_FAILURE";
// species
export const GET_PRODUCT_SPECIES_SUCCESS = "GET_PRODUCT_SPECIES_SUCCESS";
export const GET_PRODUCT_SPECIES_FAILURE = "GET_PRODUCT_SPECIES_FAILURE";
export const CREATE_PRODUCT_SPECIES_SUCCESS = "CREATE_PRODUCT_SPECIES_SUCCESS";
export const CREATE_PRODUCT_SPECIES_FAILURE = "CREATE_PRODUCT_SPECIES_FAILURE";
export const DELETE_SPECIES_REQUEST = "DELETE_SPECIES_REQUEST";
export const DELETE_SPECIES_SUCCESS = "DELETE_SPECIES_SUCCESS";
export const DELETE_SPECIES_FAILURE = "DELETE_SPECIES_FAILURE";

export const GET_ADMIN_DATA_SUCCESS = "GET_ADMIN_DATA_SUCCESS";
export const GET_ADMIN_DATA_FAIL = "GET_ADMIN_DATA_FAIL";

export const SET_CURRENT_RESOURCE_TYPE = "SET_CURRENT_RESOURCE_TYPE";
export const SET_FILTERED_PARAMS = "SET_FILTERED_PARAMS";
export const SET_FILTERED_EVENT_PARAMS = "SET_FILTERED_EVENT_PARAMS";
export const SET_CREATION_START_DATE = "SET_CREATION_START_DATE";
export const SET_CREATION_END_DATE = "SET_CREATION_END_DATE";
export const SET_UPDATE_START_DATE = "SET_UPDATE_START_DATE";
export const SET_UPDATE_END_DATE = "SET_UPDATE_END_DATE";
export const CLEAR_FILTER = "CLEAR_FILTER";

//score
export const GET_SCORE_CARD_DATA_SUCCESS = "GET_SCORE_CARD_DATA_SUCCESS";
export const GET_SCORE_CARD_DATA_FAIL = "GET_SCORE_CARD_DATA_FAIL";
export const SET_SCORE_CARD_DATA_SUCCESS = "SET_SCORE_CARD_DATA_SUCCESS";
export const SET_SCORE_CARD_DATA_FAIL = "SET_SCORE_CARD_DATA_FAIL";

export const SET_TINYMCE_CONTENT = "SET_TINYMCE_CONTENT";
export const SET_CONTENT_LANGUAGE = "SET_CONTENT_LANGUAGE";
export const RESET_CONTENT_AND_LANGUAGE = "RESET_CONTENT_AND_LANGUAGE";

export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS";
export const GET_POLICY_FAIL = "GET_POLICY_FAIL";

export const CREATE_POLICY_SUCCESS = "CREATE_POLICY_SUCCESS";
export const CREATE_POLICY_FAIL = "CREATE_POLICY_FAIL";

//NearBy//
export const GET_NEARBY_SUCCESS = "GET_NEARBY_SUCCESS";
export const GET_NEARBY_FAILURE = "GET_NEARBY_FAILURE";
export const DELETE_NEARBY_SUCCESS = "DELETE_NEARBY_SUCCESS";
export const DELETE_NEARBY_FAILURE = "DELETE_NEARBY_FAILURE";
export const CREATE_NEARBY_SUCCESS = "CREATE_NEARBY_SUCCESS";
export const CREATE_NEARBY_FAILURE = "CREATE_NEARBY_FAILURE";
export const EDIT_NEARBY_SUCCESS = "EDIT_NEARBY_SUCCESS";
export const EDIT_NEARBY_FAILURE = "EDIT_NEARBY_FAILURE";
export const RETRIEVE_NEARBY_SUCCESS = "RETRIEVE_NEARBY_SUCCESS";
export const RETRIEVE_NEARBY_FAILURE = "RETRIEVE_NEARBY_FAILURE";
export const GET_NEARBY_SPECIES_SUCCESS = "GET_NEARBY_SPECIES_SUCCESS";
export const TOGGLE_NEARBY_ACTIVATION_SUCCESS =
  "TOGGLE_NEARBY_ACTIVATION_SUCCESS";
export const TOGGLE_NEARBY_ACTIVATION_FAILURE =
  "TOGGLE_NEARBY_ACTIVATION_FAILURE";

//Form //

export const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS";
export const SET_FULLNAME = "SET_FULL_NAME";
export const SET_REASON = "SET_REASON";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_FAILED = "FORM_SUBMIT_FAILED";
export const CLEAR_FORM_FIELDS_SUCCESS = "CLEAR_FORM_FIELDS_SUCCESS";
export const CLEAR_FORM_FIELDS_FAILED = "CLEAR_FORM_FIELDS_FAILED";
export const DELETE_ACCOUNT_REQUEST_SUCCESS = "DELETE_ACCOUNT_REQUEST_SUCCESS";
export const DELETE_ACCOUNT_REQUEST_FAILED = "DELETE_ACCOUNT_REQUEST_FAILED";

export const GET_TICK_MAP_REQUEST = "GET_TICK_MAP_REQUEST";
export const GET_TICK_MAP_SUCCESS = "GET_TICK_MAP_SUCCESS";
export const GET_TICK_MAP_FAILURE = "GET_TICK_MAP_FAILURE";

export const CREATE_TICK_MAP_REQUEST = "CREATE_TICK_MAP_REQUEST";
export const CREATE_TICK_MAP_SUCCESS = "CREATE_TICK_MAP_SUCCESS";
export const CREATE_TICK_MAP_FAILURE = "CREATE_TICK_MAP_FAILURE";
export const SET_TICKMAP_LANGUAGE = "SET_TICKMAP_LANGUAGE";
export const SET_COVER_IMAGE_TICK_MAP = "SET_COVER_IMAGE_TICK_MAP";
export const UPDATE_TM_FORM_DATA = "UPDATE_TM_FORM_DATA";
export const SET_TM_SUCCESS = "SET_TM_SUCCESS";

//FAQS
export const SET_FAQS_LANGUAGE = "SET_FAQS_LANGUAGE";
export const FETCH_FAQS_REQUEST = "FETCH_FAQS_REQUEST";
export const FETCH_FAQS_SUCCESS = "FETCH_FAQS_SUCCESS";
export const FETCH_FAQS_FAILURE = "FETCH_FAQS_FAILURE";
export const UPDATE_FAQS_FORM = "UPDATE_FAQS_FORM";
export const RESET_FAQS_FORM = "RESET_FAQS_FORM";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";

export const FETCH_FAQ_BY_ID_SUCCESS = "FETCH_FAQ_BY_ID_SUCCESS";
export const FETCH_FAQ_BY_ID_REQUEST = "FETCH_FAQ_BY_ID_REQUEST";
export const FETCH_FAQ_BY_ID_FAILURE = "FETCH_FAQ_BY_ID_FAILURE";

export const SET_FAQS_CATEGORIES_LANGUAGE = "SET_FAQS_CATEGORIES_LANGUAGE";
export const ADD_FAQS_CATEGORY_SUCCESS = "ADD_FAQS_CATEGORY_SUCCESS";
export const EDIT_FAQ_CATEGORY_SUCCESS = "EDIT_FAQ_CATEGORY_SUCCESS";

export const TOGGLE_FAQ_ACTIVATION_SUCCESS = "TOGGLE_FAQ_ACTIVATION_SUCCESS";
export const TOGGLE_FAQ_ACTIVATION_FAILURE = "TOGGLE_FAQ_ACTIVATION_FAILURE";
export const FETCH_FAQS_CATEGORIES_REQUEST = "FETCH_FAQS_CATEGORIES_REQUEST";
export const FETCH_FAQS_CATEGORIES_SUCCESS = "FETCH_FAQS_CATEGORIES_SUCCESS";
export const FETCH_FAQS_CATEGORIES_FAILURE = "FETCH_FAQS_CATEGORIES_FAILURE";
export const DELETE_FAQS_CATEGORY_SUCCESS = "DELETE_FAQS_CATEGORY_SUCCESS";
export const UPDATE_FAQS_CATEGORIES_FORM = "UPDATE_FAQS_CATEGORIES_FORM";

//Admin
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const UPDATE_STATUS_USER_REQUEST = "UPDATE_STATUS_USER_REQUEST";
export const UPDATE_STATUS_USER_SUCCESS = "UPDATE_STATUS_USER_SUCCESS";
export const UPDATE_STATUS_USER_FAILURE = "UPDATE_STATUS_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const UPDATE_USER_FORM = "UPDATE_USER_FORM";
export const FETCH_USER_PERMISSIONS_REQUEST = "FETCH_USER_PERMISSIONS_REQUEST";
export const FETCH_USER_PERMISSIONS_SUCCESS = "FETCH_USER_PERMISSIONS_SUCCESS";
export const FETCH_USER_PERMISSIONS_FAILURE = "FETCH_USER_PERMISSIONS_FAILURE";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const FETCH_USER_BY_ID_REQUEST = "FETCH_USER_BY_ID_REQUEST";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";
export const FETCH_USER_BY_ID_FAILURE = "FETCH_USER_BY_ID_FAILURE";

export const RESET_ADMIN_USER_FORM = "RESET_ADMIN_USER_FORM";
export const SET_SELECTED_PERMISSIONS = "SET_SELECTED_PERMISSIONS";
export const USER_SETUP_PASSWORD_REQUEST = "USER_SETUP_PASSWORD_REQUEST";
export const USER_SETUP_PASSWORD_SUCCESS = "USER_SETUP_PASSWORD_SUCCESS";
export const USER_SETUP_PASSWORD_FAILURE = "USER_SETUP_PASSWORD_FAILURE";

export const USER_RESEND_PASSWORD_REQUEST = "USER_RESEND_PASSWORD_REQUEST";
export const USER_RESEND_PASSWORD_SUCCESS = "USER_RESEND_PASSWORD_SUCCESS";
export const USER_RESEND_PASSWORD_FAILURE = "USER_RESEND_PASSWORD_FAILURE";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

//Toolbar
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const SET_DRAWER_CLOSE = "SET_DRAWER_CLOSE";

//Event Requests
export const GET_EVENT_REQUESTS_DATA_SUCCESS =
  "GET_EVENT_REQUESTS_DATA_SUCCESS";
export const GET_EVENT_REQUESTS_DATA_FAILURE =
  "GET_EVENT_REQUESTS_DATA_FAILURE";
export const SET_SELECTED_EVENT_REQUEST = "SET_SELECTED_EVENT_REQUEST";

export const REJECT_EVENT_REQUESTS_SUCCESS = "REJECT_EVENT_REQUESTS_SUCCESS";
export const REJECT_EVENT_REQUESTS_FAILURE = "REJECT_EVENT_REQUESTS_FAILURE";

export const APPROVE_EVENT_REQUESTS_SUCCESS = "APPROVE_EVENT_REQUESTS_SUCCESS";
export const APPROVE_EVENT_REQUESTS_FAILURE = "APPROVE_EVENT_REQUESTS_FAILURE";

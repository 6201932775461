import dayjs from "dayjs";
import { getFormLanguage } from "../../component/common/GeneralHelper";
import { LANGUAGE_KEY, LANGUAGE_TYPE } from "../../constants/GeneralConstant";
import {
  DELETE_EVENT_ATTACHMENT,
  RETRIEVE_EVENT_SUCCESS,
  SET_ADDRESS,
  SET_EVENT_ATTACHMENT,
  SET_EVENT_COVER_IMAGE,
  SET_EVENT_DETAIL,
  SET_EVENT_DETAIL_LANGUAGE,
  SET_EVENT_END_DATE,
  SET_EVENT_LABEL,
  SET_EVENT_LANGUAGE,
  SET_EVENT_LINK,
  SET_EVENT_NOTIFICATION_DATE,
  SET_EVENT_NOTIFICATION_FREQUENCY,
  SET_EVENT_NOTIFICATION_INTERVAL,
  SET_EVENT_NOTIFICATION_TIME,
  SET_EVENT_START_DATE,
  SET_EVENT_TITLE,
  SET_ORGANIZOR,
  SET_TARGET_AUDIENCE,
  TOGGLE_EVENT_CATEGORY,
} from "../actions/type";

const initialState = {
  language: "EN",
  metaData: { language: "EN" },
  eventLanguage: "",
  status: "Draft",
  isAuthRequired: true,
  title: { EN: "", LU: "", RY: "" },
  address: { EN: "", LU: "", RY: "" },
  categoryIds: [],
  eventDetails: { EN: "", LU: "", RY: "" },
  targetAudience: { EN: "", LU: "", RY: "" },
  coverImage: { EN: "" },
  eventStartDateTime: "",
  eventEndDateTime: "",
  eventLabel: "",
  eventLink: "",
  notificationDatetime: dayjs(),
  notificationFrequency: 0,
  notificationInterval: [0],
  organizerId: {
    id: "",
  },
  attachments: [],
};

export const currentEventeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_EVENT_CATEGORY:
      let currentCategoryIds = [];
      const selectedCategoryId = action.payload;
      const currentIds = state.categoryIds.flatMap((c) => c.id);
      if (currentIds.includes(selectedCategoryId)) {
        const index = currentIds.indexOf(selectedCategoryId);
        currentCategoryIds = [...state.categoryIds];
        if (index > -1) {
          currentCategoryIds.splice(index, 1);
        }
      } else {
        currentCategoryIds = [...state.categoryIds, { id: selectedCategoryId }];
      }
      return {
        ...state,
        categoryIds: currentCategoryIds,
      };
    case SET_EVENT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        metaData: {
          language: action.payload,
        },
      };
    case SET_EVENT_TITLE:
      return {
        ...state,
        title: {
          ...state.title,
          [state.language]: action.payload,
        },
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: {
          ...state.address,
          [state.language]: action.payload,
        },
      };
    case SET_EVENT_DETAIL:
      return {
        ...state,
        eventDetails: {
          ...state.eventDetails,
          [state.language]: action.payload,
        },
      };
    case SET_ORGANIZOR:
      return {
        ...state,
        organizerId: {
          ...state.organizerId,
          id: action.payload,
        },
      };
    case SET_TARGET_AUDIENCE:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          [state.language]: action.payload,
        },
      };
    case SET_EVENT_COVER_IMAGE:
      return {
        ...state,
        coverImage: {
          ...state.coverImage,
          [state.language]: action.payload,
        },
      };
    case SET_EVENT_START_DATE:
      return {
        ...state,
        eventStartDateTime: action.payload,
      };
    case SET_EVENT_END_DATE:
      return {
        ...state,
        eventEndDateTime: action.payload,
      };
    case SET_EVENT_NOTIFICATION_DATE:
      return {
        ...state,
        notificationDatetime: action.payload,
      };
    case SET_EVENT_NOTIFICATION_TIME:
      return {
        ...state,
        notificationTime: action.payload,
      };
    case SET_EVENT_NOTIFICATION_FREQUENCY:
      return {
        ...state,
        notificationFrequency: action.payload,
      };
    case SET_EVENT_NOTIFICATION_INTERVAL:
      return {
        ...state,
        notificationInterval: action.payload,
      };
    case SET_EVENT_DETAIL_LANGUAGE:
      return {
        ...state,
        eventLanguage: action.payload,
      };
    case SET_EVENT_LABEL:
      return {
        ...state,
        eventLabel: action.payload,
      };
    case SET_EVENT_LINK:
      return {
        ...state,
        eventLink: action.payload,
      };
    case RETRIEVE_EVENT_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else {
        // const lang = action.payload.title[LANGUAGE.ENGLISH]
        //   ? LANGUAGE.ENGLISH
        //   : action?.payload?.metaData?.language;
        const lang = getFormLanguage(action.payload.title);
        const newItems2 = action.payload.categories.map((category) => ({
          id: category.id,
        }));
        const organizor = action?.payload?.organizer?.id ?? "";
        const languageCode = (() => {
          switch (action.payload.eventLanguage) {
            case LANGUAGE_TYPE.ENGLISH:
              return LANGUAGE_KEY.ENGLISH;
            case LANGUAGE_TYPE.LUGANDA:
              return LANGUAGE_KEY.LUGANDA;
            case LANGUAGE_TYPE.RUNYAKITARA:
              return LANGUAGE_KEY.RUNYAKITARA;
            default:
              return "";
          }
        })();
        return {
          ...state,
          title: action.payload.title,
          language: lang || LANGUAGE_KEY.ENGLISH,
          categoryIds: newItems2,
          coverImage: action.payload.coverImage,
          status: action.payload.status,
          isAuthRequired: action.payload.isAuthRequired,
          eventStartDateTime: action.payload.eventStartDateTime,
          eventEndDateTime: action.payload.eventEndDateTime,
          address: action.payload.address,
          targetAudience: action.payload.targetAudience,
          eventDetails: action.payload.eventDetails,
          organizerId: { id: organizor },
          eventLanguage: languageCode,
          eventLabel: action.payload.label,
          eventLink: action.payload.eventLink ?? "",
          attachments: action.payload.attachments ?? "",
          notificationFrequency:
            action.payload.notificationInterval[0] === 0 ? 1 : 2,
          notificationInterval: action.payload.notificationInterval ?? [15],
          notificationDatetime: action.payload.notificationDatetime ?? "",
          externalOrganizer: action.payload.externalOrganizer,
          approvalStatus: action.payload.approvalStatus,
        };
      }
    case SET_EVENT_ATTACHMENT:
      return {
        ...state,
        attachments: [
          ...state.attachments,
          {
            filename: action.payload.filename,
            name: action.payload.name,
            size: action.payload.size,
            type: action.payload.type,
          },
        ],
      };
    case DELETE_EVENT_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments[state.language].filter(
          (attachment, index) => index !== action.payload
        ),
      };
    default:
      return state;
  }
};

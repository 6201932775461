import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import BlobService from "../../services/blob-service";
import Compressor from "compressorjs";

const Content = ({ localizedContent, setContent, setHtmlImage }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const HandleEditor = (e) => {
    const plainTextContent = editorRef.current.getContent({ format: "text" });
    const trimmedContent = plainTextContent.trim(); // Remove all spaces using regex
    if (trimmedContent.length === 0) {
      dispatch(setContent(""));
    } else {
      dispatch(setContent(e));
    }
  };

  const handleImageUpload = async (blobInfo, success, failure, e) => {
    const file = blobInfo.blob();
    const maxSize = 20 * 1024 * 1024; // 20 MB in bytes
    if (file.size > maxSize) {
      failure("File size exceeds the maximum allowed size of 20 MB");
      e.windowManager.close();
      return;
    }

    try {
      new Compressor(file, {
        quality: 0.6,
        success: async (compressedFile) => {
          const filename = uuidv4();
          BlobService.uploadImage(filename, compressedFile).then(
            (response) => {
              const url = BlobService.getImageUri(filename);
              success(url);
              if (setHtmlImage) {
                dispatch(setHtmlImage(filename));
              }
            },
            (err) => {
              failure(err);
            }
          );
        },
        error: (error) => {
          console.error("Image compression error:", error);
          failure("Image compression failed");
        },
      });
    } catch (error) {
      console.error("Image compression error:", error);
      failure("Image compression failed");
    }
  };

  return (
    <Editor
      textarea="content"
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={localizedContent ? localizedContent : ""}
      onEditorChange={HandleEditor}
      init={{
        autosave_interval: "10s",
        height: window.innerWidth <= 767 ? "calc(100vh - 330px)" : "calc(100vh - 230px)",
        images_upload_max_size: 20971520, // 20MB in bytes
        menubar: "edit insert format table",
        file_picker_types: "image",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor autosave",
          "searchreplace visualblocks fullscreen",
          "insertdatetime media table paste help wordcount",
          "emoticons hr code", // added plugins for underline, strikethrough, font size
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic underline strikethrough | fontsizeselect | " + // added toolbar options for underline, strikethrough, font size
          "alignleft aligncenter alignright alignjustify | " +
          "bullist numlist outdent indent | " +
          "removeformat | link image | emoticons hr | help", // added toolbar options for link, image, and horizontal rule
        content_style: `
          .tox-tinymce {
            border: 0 !important; /* Remove the border around the editable area */
          }
        `,
        autosave_ask_before_unload: false,
        images_upload_handler: handleImageUpload, // Use the updated handler function
      }}
    />

  );
};

export default Content;

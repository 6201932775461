/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip, Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { formatDate } from "../../common/DateFormatter";
import {
  deleteProduct,
  getProducts,
  toggleProductActivation,
} from "../../../store/actions/product";
import {
  deleteNearBy,
  getNearByList,
  toggleNearByActivation,
} from "../../../store/actions/nearBy";
import { URL } from "../../../constants/URL";
import { toggleContentGating } from "../../../store/actions/learningResource";
import { CheckBox } from "@mui/icons-material";
import { CONTENT_STATES } from "../../../constants/GeneralConstant";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const NearByTable = ({ data, selectedRows, setSelectedRows }) => {
  // const { nearBy } = useSelector((state) => state.nearBy);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleSelectionChange = (selectionModel) => {
  //   // Store the IDs of selected rows
  //   setSelectedRows(selectionModel);
  // };

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const rows = data?.map((item) => {
    const fullname =
      item?.fullname?.EN || item?.fullname?.LU || item?.fullname?.RY;
    return {
      id: item?.id,
      fullname: fullname,
      updatedat:
        formatDate(item?.createdAt).date +
        " | " +
        formatDate(item?.createdAt).time +
        " by " +
        (item?.updatedBy && item?.updatedBy?.fullname
          ? item?.updatedBy?.fullname
          : "Admin"),
    };
  });

  const isMobile = useMediaQuery("(max-width:1024px)");

  const columns = [
    {
      field: "fullname",
      headerName: "FullName",
      flex: isMobile ? undefined : 3, // Allow auto sizing for mobile
      width: isMobile ? 150 : undefined, // Adjust width based on screen size
    },
    //   {
    //     field: "updatedat",
    //     headerName: "Last Updated",
    //     flex: 1,
    //   },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: isMobile ? undefined : 1, // Allow auto sizing for mobile
      width: isMobile ? 150 : undefined, // Adjust width based on screen size
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );

        const [open, setOpen] = useState(false);

        //Will Test this later//

        const HandleGate = (params) => {
          const nearby = data?.find((obj) => obj.id === params.id);
          dispatch(toggleContentGating(URL.NEARBYHELP, nearby))
            .then(() => {
              dispatch(getNearByList(URL.NEARBYHELP));
              if (nearby.isAuthRequired) {
                toast.success("Successfully ungated!");
              } else {
                toast.success("Successfully gated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate nearby");
            });
        };

        const handleDelete = (params) => {
          dispatch(deleteNearBy(URL.NEARBYHELP, params.id))
            .then(() => {
              // Refresh the article list
              dispatch(getNearByList(URL.NEARBYHELP)).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete NearBy!");
            });
        };

        const HandleActivation = (params) => {
          const res = data.find((obj) => {
            return obj.id === params.id;
          });

          dispatch(
            toggleNearByActivation(URL.NEARBYHELP, params.id, {
              status: !res.status,
            })
          )
            .then(() => {
              if (res.status) {
                toast.success("Successfully deactivated!");
              } else {
                toast.success("Successfully activated!");
              }
            })
            .catch(() => {
              toast.error("Failed to activate/deactivate resource!");
            })
            .finally(() => {
              dispatch(getNearByList(URL.NEARBYHELP));
            });
        };

        const nearBy = data?.find((obj) => obj.id === params.id);

        let activation;
        if (nearBy && nearBy.status) {
          activation = (
            <Tooltip title="deactivate">
              <VisibilityOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }

        let gated;
        if (nearBy && nearBy.isAuthRequired === true) {
          gated = (
            <Tooltip title="Ungate">
              <LockPersonOutlinedIcon
                onClick={() => HandleGate(params)}
              />
            </Tooltip>
          );
        } else {
          gated = (
            <Tooltip title="Gate">
              <LockOpenOutlinedIcon
                onClick={() => HandleGate(params)}
              />
            </Tooltip>
          );
        }

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                onClick={() => navigate(`/nearby/${params.id}/edit`)}
              />
            </Tooltip>
            {gated || null}
            {activation || null}
            {/* {isActivate ? activation : null} */}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon
                onClick={() => setOpen(true)}
              />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
          </>
        );
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 100,
      // renderCell: (params) => {
      //   console.log("params:", params);
      //   // Your action cell rendering logic...
      // },
    },
    // {
    //   field: "selection",
    //   headerName: (
    //     <input
    //       type="checkbox"
    //       onChange={handleSelectAllRows}
    //       checked={selectedRows.length === data.length}
    //       style={{ transform: "scale(1.5)" }}
    //     />
    //   ),
    //   width: 100,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <input
    //         type="checkbox"
    //         onChange={(event) => handleRowCheckboxChange(event, params.id)}
    //         checked={selectedRows.includes(params.id)}
    //         style={{ transform: "scale(1.5)" }}
    //       />
    //     );
    //   },
    // },
  ];

  const rows2 = data?.map((item) => {
    const fullname =
      item?.fullname?.EN || item?.fullname?.LU || item?.fullname?.RY;
    return {
      id: item?.id,
      fullname: fullname,
      updatedat: item.updatedAt
        ? formatDate(item?.updatedAt)?.date +
          " | " +
          formatDate(item?.updatedAt)?.time +
          " by " +
          (item?.updatedBy && item?.updatedBy?.fullname
            ? item?.updatedBy?.fullname
            : "Admin")
        : null,
    };
  });

  function CustomNoRowsOverlay() {
    return (
      <Box
        className="nofound-box"
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          flexDirection: 'column',
          textAlign: 'left',
        }}
      >
        <span className="nofound-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="24"
            height="24"
          >
            <path d="M10.29 3.86L1.82 19a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-15.14a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <circle cx="12" cy="17" r="1"></circle>
          </svg>
        </span>
        <h4 className="title">No results found!</h4>
        <h5 className="sub-title">Try changing the search criteria and try again</h5>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: 'calc(100vh - 172px)',
          '@media (max-width: 767px)': {
            height: "calc(100vh - 272px)",
          },
        }}
      >
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            disableColumnMenu
            rowsPerPageOptions={[20]}
            // options={{
            //   showSelectAllCheckbox: true,
            // }}
            checkboxSelection
            checkboxSelectionVisibleOnly={true}
            onSelectionModelChange={handleSelectionChange}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            disableColumnMenu
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: true,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedat", sort: "desc" }],
              },
            }}
          />
        )}
      </Box>
    </>
  );
};

export default NearByTable;

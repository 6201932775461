// External imports
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Link,
} from '@mui/material';

// Internal imports
import FormValidation from '../common/FormValidations';
import { forgetPassword } from '../../store/actions/auth';
import { clearMessage } from '../../store/actions/message';

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: FormValidation.ForgetSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      dispatch(forgetPassword(values.email))
        .then(() => {
          navigate('/code', {
            state: { showToast: true, email: values.email },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  const handleLoginClick = () => {
    dispatch(clearMessage());
    navigate('/login');
  };

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      {/* Left: Forget Password Form */}
      <Container
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1,
          padding: 4,
        }}
      >
        <Box sx={{ px: 3 }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Box
                component="img"
                src="/img/elanco-logo.svg"
                alt="Elanco"
                sx={{
                  height: '80px',
                  objectFit: 'cover',
                  mb: 3,
                }}
              />
              <Typography color="textPrimary" variant="h4" sx={{ mb: 1, fontSize: '1.5rem' }}>
                Forget Password
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Don’t worry! Just give us your email and we’ll send a link to reset your password.
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loading && (
                  <span>
                    <CircularProgress size={24} sx={{ marginRight: 1 }} />
                  </span>
                )}
                <span>Submit</span>
              </Button>
              {message && (
                <div className="form-group" style={{ paddingTop: '1em' }}>
                  <Alert severity="error">{message}</Alert>
                </div>
              )}
              <Typography sx={{ mt: 2 }}>
                <Link
                  sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  href="#"
                  onClick={handleLoginClick}
                >
                  <ArrowBackIcon 
                    sx={{ 
                      mr: 0.5,
                      height: '0.75em',
                      width: '0.75em',
                    }} 
                  />
                  <span>Back to Login</span>
                </Link>
              </Typography>
            </Box>
          </form>
        </Box>
      </Container>

      {/* Right: Image */}
      <Box
        className="hero-img"
        sx={{
          flex: 1,
          backgroundImage: 'url(/img/elanco-admin-cattle.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: { xs: 'none', sm: 'block', md: 'block' },
        }}
      ></Box>
    </Box>
  );
}

export default ForgetPassword;

// external import
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { trackPromise } from 'react-promise-tracker';
import './style.scss';

// internal import
import FormValidation from '../common/FormValidations';
import { login, permissions } from '../../store/actions/auth';
import Spinner from '../layout/Spinner';
import { clearMessage } from '../../store/actions/message';

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const handleForgetPasswordClick = () => {
    dispatch(clearMessage());
    navigate('/forget');
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: FormValidation.LoginSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      actions.setSubmitting(false);
      trackPromise(dispatch(login(values.email, values.password)))
        .then((data) => {
          if (!!data?.user?.isAdmin) {
            trackPromise(dispatch(permissions(data.user.id)))
              .then(() => {
                setLoading(false);
                navigate('/dashboard');
              })
              .catch(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        {/* Left: Login Form */}
        <Container
          maxWidth="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
            padding: 4,
            // '@media (min-width:600px)': {
            //   paddingLeft: '32px',
            //   paddingRight: '32px',
            // },
          }}
        >
          <Box sx={{px: 3}}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: 3 }}>
              <Box
                  component="img"
                  src='/img/elanco-logo.svg'
                  alt="Elanco"
                  sx={{
                    height: '80px',
                    objectFit: "cover",
                    mb: 3
                  }}
                />
                <Typography color="textPrimary" variant="h4" sx={{mb: 1, fontSize: '1.5rem'}}>
                  Sign in
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Welcome to Elanco! Give your account details below to log in.
                </Typography>
              </Box>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading && (
                    <span>
                      <CircularProgress size={24} sx={{ marginRight: 1 }} />
                    </span>
                  )}
                  <span>Login</span>
                </Button>

                {message && (
                  <div className="form-group" style={{ paddingTop: '1em' }}>
                    <Alert severity="error">{message}</Alert>
                  </div>
                )}

                <Typography sx={{ mt: 2 }}>
                  <Link 
                  sx={{
                    textDecoration: "none",
                    fontSize: '14px',
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }} 
                  href="#" onClick={handleForgetPasswordClick}>
                    Forgot password?
                  </Link>
                </Typography>
              </Box>
            </form>
          </Box>
        </Container>

        {/* Right: Image */}
        <Box
          className="hero-img"
          sx={{
            flex: 1,
            backgroundImage: 'url(/img/elanco-admin-cattle.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: { xs: 'none', sm: 'block', md: 'block' },
          }}
        ></Box>
      </Box>
      <Spinner />
    </>
  );
}

export default Login;

export const LOGIN_URL = "/login";
export const RESET_PASSWORD_URL = "/set-password";
export const FORGET_PASSWORD_URL = "/forget";
export const SECURITY_CODE_URL = "/code";
export const SETUP_PASSWORD_URL = "/setup-password";
export const VERIFY_OTP_URL = "/verify-otp";

export const ARTICLE_URL = "/article";
export const NEW_ARTICLE_URL = "/new-article";
export const EDIT_ARTICLE_URL = "article/:id/edit";

export const VIDEO_URL = "/video";
export const NEW_VIDEO_URL = "/new-video";
export const EDIT_VIDEO_URL = "/video/:id/edit";

export const TRAINING_URL = "/training";
export const NEW_TRAINING_URL = "/new-training";
export const EDIT_TRAINING_URL = "training/:id/edit";

export const GALLERY_URL = "/gallery";
export const NEW_GALLERY_URL = "/new-gallery";
export const EDIT_GALLERY_URL = "/gallery/:id/edit";

export const TICK_URL = "/know-your-tick";
export const EDIT_TICK_URL = "/know-your-tick/:id/edit";

export const EVENT_URL = "/events";
export const NEW_EVENT_URL = "/new-events";
export const EDIT_EVENT_URL = "events/:id/edit";

export const EVENT_REQUEST_URL = "/event-requests";
// export const NEW_EVENT_URL = "/new-events";
export const VIEW_EVENT_URL = "event-requests/:id/view";
export const EDIT_EVENT_REQUEST_URL = "events-requests/:id/edit";

export const PRODUCT_URL = "/products";
export const NEW_PRODUCT_URL = "/new-product";
export const EDIT_PRODUCT_URL = "product/:id/edit";

export const SCORE_URL = "/score";
export const PREMIUM_SCORE_URL = "/premium-scorecard";
export const CHART_URL = "/dashboard";
export const TERM_AND_CONDITION_URL = "/terms-and-condition";
export const DATAPOLICY_URL = "/data-policy";
export const CONTACTUS_URL = "/contact-us";

export const NEARBY_URL = "/nearby";
export const NEW_NEARBY_URL = "/new-nearbylist";
export const EDIT_NEARBY_URL = "nearby/:id/edit";
// export const NEARBY_URL = '/NearBy';
// export const NEW_NEARBY_URL = '/new-nearby';
export const NEARBYHELP_BULKIMPORT_URL = "/nearbyHelp/bulkImport";
export const HELP_URL = "/help/delete-account";
export const CONFIRM_DELETION = "/confirm-deletion";

//CONTENT
export const CONTENT_SPECIES = "/content/species";

export const SPECIES = "/species";

export const GET_EVENT_CATEGORY = "/event-category";
export const POST_EVENT_CATEGORY = "/event-category";
export const EVENT_CATEGORY_BY_ID = "/event-category/:id";

export const GET_SCORECARD_LIST = "/scorecard/list";
export const SCORECARD = "/scorecard";

export const TICK_MAP_URL = "/tick-map-management";
export const TICK_MAP_SEND_NOTIFICATION =
  "/tick-map-management/send-notification";

export const FAQS_URL = "/faq";
export const EDIT_FAQS_URL = "/faq/:id/edit";
export const FAQS_STATUS_TOGGLE = "/faq/:id/status/:status";

export const FAQ_CATEGORIES_URL = "/faq-category";

// Admins
export const ADMIN_URL = "/admin/all";
export const ADMIN = "/admin";
export const ADMIN_SETUP_PASSWORD = "/admin/password-setup";
export const VERIFY_OTP = "/auth/otp";
export const RESEND_OTP = "/auth/resend-otp";
export const ADMIN_RESEND_PASSWORD_EMAIL = "/admin/resend-password-setup-email";
export const GET_ADMIN_BY_ID = "/admin/:id";
export const ADMIN_STATUS_UPDATE = "/admin/:id/active/:status";
export const ADMIN_DELETE = "/admin/:id";
export const GET_ALL_PERMISSIONS = "/user-permission/list";
export const EXPIRY_TOKEN_PERMISSIONS = "/auth/verify-token-expiry";

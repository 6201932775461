import React from "react";
import { TextField, Box, Button, Stack, Typography } from "@mui/material";

const Header = ({ formik, handleFieldChange }) => {
  return (
    <>
      <Stack>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            mb: 1.5,
            pt: 0.75,
            pb: 2,
            borderBottom: "1px solid #E1E1E1",
            gap: 1,
          }}
        >
          <Typography variant="h5">Add Sub Admin</Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "12px",
              flexGrow: 1,
              justifyContent: "end",
              "@media (max-width: 767px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <TextField
              id="firstname"
              name="firstname"
              size="small"
              className="firstname"
              required
              value={formik.values.firstname || ""}
              variant="outlined"
              onChange={(e) => handleFieldChange("firstname", e.target.value)}
              placeholder={`First Name`}
              fullWidth
            />
            <TextField
              id="lastname"
              name="lastname"
              size="small"
              className="lastname"
              required
              value={formik.values.lastname || ""}
              variant="outlined"
              onChange={(e) => handleFieldChange("lastname", e.target.value)}
              placeholder={`Last Name`}
              fullWidth
            />

            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                width: "150px",
                "@media (max-width: 767px)": {
                  width: "100%",
                },
              }}
              // onClick={() => navigate(route)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Header;

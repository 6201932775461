/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import DiamondSharpIcon from "@mui/icons-material/DiamondSharp";
import styled from "@emotion/styled";
import { Radio, Tooltip, Box, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { formatDateAndTime, formatDate } from "./DateFormatter";
import {
  deleteRecord,
  toggleContentActivation,
  toggleContentGating,
  deleteLearningResource,
  toggleContentMarkPremium,
} from "../../store/actions/learningResource";

import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { URL } from "../../constants/URL";
import { CONTENT_STATES } from "../../constants/GeneralConstant";
import MenuList from "../widgets/menuList";
import { ar } from "date-fns/locale";
import { getFullName, getStatusClass } from "./GeneralHelper";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}

const LearningResourceTable = (props) => {
  const {
    data,
    resourceType,
    isGating,
    deleteURL,
    listService,
    listURL,
    isActivate,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { learningResource } = useSelector((state) => state.learningResource);
  const currentResourceType = useSelector((state) => state.resourceType);
  const resource = learningResource.filter(
    (temp) => temp.learningResourceType === resourceType
  );

  const isMobile = useMediaQuery("(max-width:1024px)");

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: isMobile ? 200 : 300, // Adjust width based on screen size
    },
    {
      field: "categories",
      headerName: "Category",
      flex: isMobile ? undefined : 1, // Allow auto sizing for mobile
      width: isMobile ? 160 : undefined, // Adjust width based on screen size
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMobile ? undefined : 0.6, // Allow auto sizing for mobile
      width: isMobile ? 130 : undefined, // Adjust width based on screen size
      renderCell: (params) => {
        return (
          <div className={getStatusClass(params.row.status)}>
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "updatedat",
      headerName: "Last Updated",
      flex: isMobile ? undefined : 1, // Allow auto sizing for mobile
      width: isMobile ? 250 : undefined, // Adjust width based on screen size
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: isMobile ? undefined : 1, // Allow auto sizing for mobile
      width: isMobile ? 160 : undefined, // Adjust width based on screen size
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );

        const [open, setOpen] = useState(false);

        const handleDelete = (params) => {
          dispatch(deleteRecord(params.id))
            .then(() => {
              // Refresh the article list
              dispatch(listService(listURL, currentResourceType)).then(() => {
                toast.success("Successfully deleted!");
              });
            })
            .catch((error) => {
              toast.error("Failed to delete resource!");
            });
        };

        const HandleGate = (params) => {
          const res = resource.find((obj) => obj.id === params.id);
          dispatch(toggleContentGating(URL.RESOURCE, res))
            .then(() => {
              if (res.isAuthRequired) {
                toast.success("Successfully ungated!");
              } else {
                toast.success("Successfully gated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate resource!");
            });
        };

        const handleMarkPremium = (params) => {
          const res = resource.find((obj) => {
            return obj.id === params.id;
          });
          dispatch(toggleContentMarkPremium(params.id, res))
            .then(() => {
              if (res.isPremium) {
                toast.success("Successfully Un Marked as Premium!");
              } else {
                toast.success("Successfully Marked as Premium!");
              }
            })
            .catch(() => {
              toast.error("Failed to Marked/un Marked resource!");
            });
        };

        const HandleActivation = (params) => {
          const res = resource.find((obj) => {
            return obj.id === params.id;
          });
          dispatch(toggleContentActivation(params.id, res))
            .then(() => {
              if (res.status === CONTENT_STATES.PUBLISHED) {
                toast.success("Successfully deactivated!");
              } else {
                toast.success("Successfully activated!");
              }
            })
            .catch(() => {
              toast.error("Failed to gate/ungate resource!");
            });
        };

        const article = resource.find((obj) => obj.id === params.id);

        let gated;
        if (article && article.isAuthRequired === true) {
          gated = (
            <Tooltip title="Ungate">
              <LockPersonOutlinedIcon
                onClick={() =>
                  article.isPremium
                    ? toast.error("Premium can't be marked as Gated")
                    : HandleGate(params)
                }
              />
            </Tooltip>
          );
        } else {
          gated = (
            <Tooltip title="Gate">
              <LockOpenOutlinedIcon
                onClick={() =>
                  article.isPremium
                    ? toast.error("Premium can't be marked as Gated")
                    : HandleGate(params)
                }
              />
            </Tooltip>
          );
        }

        let activation;
        if (article && article.status === "Published") {
          activation = (
            <Tooltip title="deactivate">
              <VisibilityOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }

        let premium;
        if (article && article.isPremium === true) {
          premium = (
            <Tooltip title="Unmark Premium">
              <DiamondSharpIcon onClick={() => handleMarkPremium(params)} />
            </Tooltip>
          );
        } else {
          premium = (
            <Tooltip title="Mark Premium">
              <DiamondOutlinedIcon onClick={() => handleMarkPremium(params)} />
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                onClick={() =>
                  navigate(`/${resourceType.toLowerCase()}/${params.id}/edit`)
                }
              />
            </Tooltip>
            {isGating ? gated : null}

            {isActivate ? activation : null}
            {resourceType !== "Gallery" && premium}

            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon onClick={() => setOpen(true)} />
            </Tooltip>
            <DeleteConfirmationDialog
              open={open}
              onClose={() => setOpen(false)}
              onDelete={() => handleDelete(params)}
            />
            {/* {article?.isAuthRequired && ( */}
            {/* )} */}
          </>
        );
      },
    },
  ];

  const rows = resource.map((item) => {
    let title = "";
    if (resourceType === "Tick") {
      title = item?.title;
    } else {
      title = item?.title?.EN || item?.title?.LU || item?.title?.RY;
    }

    const statusString =
      item.status === CONTENT_STATES.SCHEDULED && item.publishedAt
        ? `${item.status} \n ${formatDateAndTime(item.publishedAt)}`
        : item.status;

    return {
      id: item.id,
      title: title?.substring(0, 25) + (title?.length > 25 ? "..." : ""),
      categories: item?.categories
        ?.map(
          (category) =>
            category?.title?.EN || category?.title?.LU || category?.title?.RY
        )
        .join(","),
      status: statusString,
      updatedat: item.updatedAt
        ? `${formatDate(item.updatedAt).date} | ${
            formatDate(item.updatedAt).time
          } by ${getFullName(item.updatedBy)}`
        : `${formatDate(item.createdAt).date} | ${
            formatDate(item.createdAt).time
          } by ${getFullName(item.updatedBy)}`,
    };
  });

  const rows2 = data.map((item) => {
    let title = "";
    if (resourceType === "Tick") {
      title = item?.title;
    } else {
      title = item?.title?.EN || item?.title?.LU || item?.title?.RY;
    }

    const statusString =
      item.status === CONTENT_STATES.SCHEDULED && item.publishedAt
        ? `${item.status} \n ${formatDateAndTime(item.publishedAt)}`
        : item.status;

    return {
      id: item.id,
      title: title?.substring(0, 25) + (title?.length > 25 ? "..." : ""),
      categories: item.categories
        ?.map(
          (category) =>
            category?.title?.LU || category?.title?.EN || category?.title?.RY
        )
        .join(","),
      status: statusString,
      updatedat: item.updatedAt
        ? `${formatDate(item.updatedAt).date} | ${
            formatDate(item.updatedAt).time
          } by ${getFullName(item.updatedBy)}`
        : `${formatDate(item.createdAt).date} | ${
            formatDate(item.createdAt).time
          } by ${getFullName(item.updatedBy)}`,
    };
  });

  function CustomNoRowsOverlay() {
    return (
      <Box
        className="nofound-box"
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span className="nofound-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="24"
            height="24"
          >
            <path d="M10.29 3.86L1.82 19a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-15.14a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <circle cx="12" cy="17" r="1"></circle>
          </svg>
        </span>
        <h4 className="title">No results found!</h4>
        <h5 className="sub-title">
          Try changing the search criteria and try again
        </h5>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 172px)",
          "@media (max-width: 767px)": {
            height: "calc(100vh - 272px)", // Full width for screens <= 767px
          },
        }}
      >
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            disableColumnMenu
            rowsPerPageOptions={[20]}
            checkboxSelectionVisibleOnly={false}
            pagination
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedAt" }],
                pagination: { paginationModel: { pageSize: 20 } },
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            disableColumnMenu
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedAt" }],
              },
            }}
          />
        )}
      </Box>
    </>
  );
};

export default LearningResourceTable;

import React from "react";
import { TextField, FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setTitle } from "../../store/actions/currentEvent";
import { localizedTitleSelector } from "../../store/reducers/selector";
import EventButtons from "./EventButtons";

const EventHeader = ({ dateError }) => {
  const dispatch = useDispatch();
  // const [lang, setLang] = useState('EN');
  const language = useSelector((state) => state.currentEvent.language);
  const eventTitle = useSelector(localizedTitleSelector);

  const handleLanguage = (event) => {
    const language = event.target.value;
    // setLang(language);
    dispatch(setLanguage(language));
  };
  const handleTitleChange = (e) => {
    const text = e.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setTitle(trimmedText));
  };

  return (
      <>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mb: 1.5,
            pt: 0.75,
            pb: 2,
            gap: 1,
            borderBottom: '1px solid #E1E1E1',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5">
            Add Event
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: '100%',
              gap: "12px",
              '@media (max-width: 820px)': {
                flexGrow: 1,
                justifyContent: "end",          
              },
              '@media (max-width: 767px)': {
                flexWrap: "wrap",
                gap: '12px',         
              },
            }}
          >

          <TextField
            id="title"
            size="small"
            className="title"
            required
            value={eventTitle || ""}
            variant="outlined"
            onChange={handleTitleChange}
            fullWidth
            placeholder={`Enter title for your event`}
          />
          <FormControl
            size="small"
            className="selector"
            sx={{ 
              minWidth: "10rem",
            }}
          >
            <Select
              onChange={handleLanguage}
              value={language}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
        {/* <EventButtons dateError={dateError}></EventButtons> */}
      </>
  );
};

export default EventHeader;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveRequest,
  cancelRequest,
  rejectRequest,
} from "../../../store/actions/eventRequests";
import { setCurrentEvent } from "../../../store/actions/currentEvent";
import BlobService from "../../../services/blob-service";
import { toast } from "react-toastify";
import { EVENT_STATUS } from "../../../constants/GeneralConstant";

const EventRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { selectedRequest: eventDetails } = useSelector(
    (state) => state.eventRequests
  ); // Assumes data is in Redux store

  const handleCancel = () => {
    navigate("/event-requests");
  };

  const handleApprove = () => {
    dispatch(approveRequest(eventDetails))
      .then((res) => {
        navigate("/event-requests");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleReject = () => {
    dispatch(rejectRequest(eventDetails))
      .then((res) => {
        navigate("/event-requests");
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleEditAndApprove = () => {
    // dispatch(editAndApproveAction(eventDetails.id));
    dispatch(setCurrentEvent(eventDetails));
    navigate(`/events/${eventDetails.id}/edit`, {
      state: { isRequest: true },
    });
  };

  useEffect(() => {
    if (!eventDetails) {
      navigate(-1);
    }
  }, []);

  const getLocalizedData = (data) => {
    return data?.["EN"] || data?.["LU"] || data?.["RY"];
  };

  const isPendingRequest =
    eventDetails?.approvalStatus !== EVENT_STATUS.PENDING;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mb: 1.5,
          pt: 0.75,
          pb: 2,
          gap: 1,
          borderBottom: "1px solid #E1E1E1",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Event Details
        </Typography>
      </Box>
      <Table
        sx={{
          border: "1px solid #e1e1e1",
          "& th, & td": {
            border: "1px solid #e1e1e1",
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>User</strong>
            </TableCell>
            <TableCell>{`${eventDetails?.createdBy?.firstname} ${eventDetails?.createdBy?.lastname}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Email/Number</strong>
            </TableCell>
            <TableCell>{eventDetails?.createdBy?.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>{getLocalizedData(eventDetails?.title)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Cover Image</strong>
            </TableCell>
            <TableCell>
              {getLocalizedData(eventDetails?.coverImage) ? (
                <ImageTooltip
                  imgSrc={BlobService.getImageUri(
                    getLocalizedData(eventDetails?.coverImage)
                  )}
                >
                  <p className="hyperlink-color">
                    {" "}
                    {getLocalizedData(eventDetails?.coverImage)}
                  </p>
                </ImageTooltip>
              ) : (
                "No image provided"
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Address</strong>
            </TableCell>
            <TableCell>{getLocalizedData(eventDetails?.address)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Date</strong>
            </TableCell>
            <TableCell>
              {new Date(eventDetails?.eventStartDateTime).toLocaleDateString()}{" "}
              - {new Date(eventDetails?.eventEndDateTime).toLocaleDateString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Time</strong>
            </TableCell>
            <TableCell>
              {new Date(eventDetails?.eventStartDateTime).toLocaleTimeString()}{" "}
              - {new Date(eventDetails?.eventEndDateTime).toLocaleTimeString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Audience</strong>
            </TableCell>
            <TableCell>
              {getLocalizedData(eventDetails?.targetAudience)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Details</strong>
            </TableCell>
            <TableCell>
              <div
                dangerouslySetInnerHTML={{
                  __html: getLocalizedData(eventDetails?.eventDetails),
                }}
              ></div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Category</strong>
            </TableCell>
            <TableCell>
              {eventDetails?.categories
                .map((cat) => getLocalizedData(cat?.title))
                .join(", ")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Organizer</strong>
            </TableCell>
            <TableCell>
              {eventDetails?.externalOrganizer &&
              Object.keys(eventDetails?.externalOrganizer)?.length > 0 ? (
                <span>
                  {eventDetails?.externalOrganizer?.name} |{" "}
                  <a
                    href={eventDetails?.externalOrganizer?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {eventDetails?.externalOrganizer?.url}
                  </a>
                </span>
              ) : (
                <span>
                  {getLocalizedData(eventDetails?.organizer?.fullname)} |{" "}
                  <a
                    href={getLocalizedData(eventDetails?.organizer?.details)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getLocalizedData(eventDetails?.organizer?.details)}
                  </a>
                </span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Attachments</strong>
            </TableCell>
            <TableCell>
              {eventDetails?.attachments
                ? eventDetails?.attachments.map((file) => (
                    <ImageTooltip
                      imgSrc={BlobService.getImageUri(file?.filename)}
                    >
                      <p className="hyperlink-color">{file?.filename}</p>
                    </ImageTooltip>
                  ))
                : "No attachments provided"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            "@media (max-width: 767px)": {
              minWidth: "100%",
            },
          }}
          variant="contained"
          color="primary"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{
            "@media (max-width: 767px)": {
              minWidth: "100%",
            },
          }}
          disabled={isPendingRequest}
          variant="contained"
          color="secondary"
          onClick={handleReject}
        >
          Reject
        </Button>
        <Button
          sx={{
            "@media (max-width: 767px)": {
              minWidth: "100%",
            },
          }}
          disabled={isPendingRequest}
          variant="contained"
          color="primary"
          onClick={handleApprove}
        >
          Approve
        </Button>
        <Button
          sx={{
            "@media (max-width: 767px)": {
              minWidth: "100%",
            },
          }}
          disabled={isPendingRequest}
          variant="contained"
          color="success"
          onClick={handleEditAndApprove}
        >
          Edit & Approve
        </Button>
      </Box>
    </div>
  );
};

export default EventRequest;

const ImageTooltip = ({ imgSrc, children }) => {
  return (
    <Tooltip
      placement="top"
      title={
        <img
          src={imgSrc}
          alt="Preview"
          style={{
            width: 150,
            height: "auto",
            objectFit: "cover",
            borderRadius: 8,
          }}
        />
      }
      arrow
    >
      {children}
    </Tooltip>
  );
};

import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  sendNotification,
  setLanguage,
} from "../../../store/actions/tickMapManagement";
import LanguageSelector from "./LanguageSelector";
import { useNavigate } from "react-router-dom";
import NotificationDialog from "../../dialogs/NotificationDialog";

export default function TickMapHeader(props) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.tickMap.selectedLanguage);
  const navigate = useNavigate();

  const handleLanguage = (event) => {
    const language = event.target.value;
    dispatch(setLanguage(language));
  };

  return (
    <Box
      sx={{
        display: "flex",
        mb: 1.5,
        pt: 0.75,
        pb: 2,
        gap: 1,
        borderBottom: '1px solid #E1E1E1',
        alignItems: 'flex-start',
        '@media (max-width: 767px)': {
          flexDirection: 'column',
        },
        }}
    >
      <Typography variant="h5">{props.header}</Typography>
      <LanguageSelector handleLanguage={handleLanguage} lang={language} />
      <Button
            variant="contained"
            size="small"
            type="submit"
            onClick={() => navigate(props.route)}
            sx={{
              marginLeft: 'auto',
              '@media (max-width: 767px)': {
                minWidth: '100%', // Full width for screens <= 820px
              },
            }}
          >
            Publish
          </Button>

      {/* <Box
          sx={{
            display: "flex",
            gap: "12px",
            flexGrow: 1,
            justifyContent: "end", 
            '@media (max-width: 767px)': {
              // flexWrap: "wrap",        
            },
          }}
        > */}
          {/* <Button
            variant="contained"
            size="small"
            onClick={handleSendNotification}
          >
            Send Notification Only
          </Button> */}
          
          
      {/* </Box> */}
      {props.notificationDialogOpen && (
        <NotificationDialog
          open={props.notificationDialogOpen}
          onSend={props.sendNotification}
          handleSendNotification={props.handleSendNotification}
          onClose={() => props.setNotificationDialogOpen(false)}
        />
      )}
    </Box>
  );
}

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createResource,
  editResource,
  ListingRecord,
} from "../../store/actions/learningResource";
import {
  localizedContentSelector,
  localizedCoverImage,
  localizedThumbnailSelector,
  localizedUrlSelector,
  localizedVideoSelector,
} from "../../store/reducers/selector";
import { validateResource } from "../validations/validateResource";
import usePrompt from "../hook/usePrompt";
import Preview from "../preview/Preview";
import { toast } from "react-toastify";
import { URL } from "../../constants/URL";
import PreviewIcon from "@mui/icons-material/Preview";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Button, Box } from "@mui/material";
import { IconButton, Tooltip } from "@mui/material";
import CalenderDialog from "../common/CalenderDialog.js";
import { useRef } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import SplitButton from "./SplitButtons.js";
import dayjs from "dayjs";
import { CONTENT_STATES, RESOURCES } from "../../constants/GeneralConstant.js";

const ResourceButton = ({
  error,
  addResourceUrl,
  ListingUrl,
  publishedDate,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isPublished, setIsPublished] = useState(true);
  const localizedVideo = useSelector(localizedVideoSelector);
  const localizedUrl = useSelector(localizedUrlSelector);
  const localizedThumbail = useSelector(localizedThumbnailSelector);
  const localizedContent = useSelector(localizedContentSelector);
  const [previewOpen, setPreviewOpen] = useState(false);
  const resourceType = useSelector((state) => state.resourceType);
  const article = useSelector((state) => state.article);
  const coverImage = useSelector(localizedCoverImage);
  const [openCalender, setOpenCalender] = useState(null);
  let dateTimeRef = useRef(null);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleSaveResource = async (status) => {
    const validationError = validateResource(
      article,
      error,
      localizedVideo,
      localizedUrl,
      localizedThumbail,
      localizedContent
    );
    if (validationError) {
      toast.error(validationError);
      return;
    }

    let filteredArticle = article;

    switch (article.learningResourceType) {
      case RESOURCES.ARTICLE:
        filteredArticle = {
          ...article,
          bodyPart: undefined,
          publisherIds: undefined,
          videoUrl: undefined,
        };
        break;
      case RESOURCES.VIDEO:
      case RESOURCES.TRAINING:
        filteredArticle = {
          ...article,
          authorId: undefined,
          coverImage: undefined,
          bodyPart: undefined,
        };
        break;
      case RESOURCES.GALLERY:
        filteredArticle = {
          ...article,
          authorId: undefined,
          bodyPart: undefined,
          publisherIds: undefined,
          videoUrl: undefined,
        };

        // case 'NearBy':
        //   filteredArticle =
        //   {
        //     items: [
        //       {
        //         fullname: article.fullname,
        //         addressLine1: article.addressLine1,
        //         phone: article.phone,
        //         fullTimeIncharge: article.fullTimeIncharge,
        //         district: article.district,
        //       }
        //     ]
        //   }
        break;
      default:
        break;
    }

    filteredArticle.status = status;

    if (filteredArticle.status === CONTENT_STATES.SCHEDULED) {
      filteredArticle.publishedAt = combinedDateTime();
    }
    try {
      if (id) {
        delete filteredArticle?.publishedAt;
        await dispatch(editResource(addResourceUrl, id, filteredArticle)).then(
          () => {
            Object.keys(resourceType).length > 0 &&
              dispatch(ListingRecord(ListingUrl, resourceType));
          }
        );
        toast.success(`${article.learningResourceType} edited successfully!`);
      } else {
        await dispatch(createResource(addResourceUrl, filteredArticle)).then(
          () => {
            Object.keys(resourceType).length > 0 &&
              dispatch(
                ListingRecord(URL.LEARNING_RESOURCE_LISTING, resourceType)
              );
          }
        );
        toast.success(
          `${
            article.learningResourceType
          } successfully ${filteredArticle?.status?.toLowerCase()}!`
        );
      }
      navigate(`/${article.learningResourceType.toLowerCase()}`);
    } catch (error) {
      toast.error(
        `Failed to ${id ? "edit" : "create"} ${article.learningResourceType}!`
      );
    }
  };

  const handleCreateArticle = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.DRAFT);
  };

  const handlePublishArticle = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.PUBLISHED);
  };

  const handleScheduleArticle = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.SCHEDULED);
  };

  const isBlocking = () => {
    return (
      (article?.title?.length > 0 ||
        localizedContent?.length > 0 ||
        localizedVideo ||
        localizedThumbail ||
        coverImage) &&
      isPublished
    );
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  const handleCloseCalender = () => {
    setOpenCalender(false);
  };

  const handleCalenderOk = () => {
    // setButtonStatus(STATUS.SCHEDULED);

    setOpenCalender(false);
    handleScheduleArticle();
  };

  const combinedDateTime = () => {
    const selectedDate = dateTimeRef.current.getSelectedDate();
    const selectedTime = dateTimeRef.current.getSelectedTime();

    let combinedDateTime = dayjs(selectedDate);
    if (selectedDate && selectedTime) {
      // Combine date and time
      combinedDateTime = dayjs(selectedDate)
        .set("hour", dayjs(selectedTime).hour())
        .set("minute", dayjs(selectedTime).minute())
        .set("second", dayjs(selectedTime).second())
        .set("millisecond", dayjs(selectedTime).millisecond());

      // Format as ISO 8601 string
    }
    const localDateTime = combinedDateTime.format(); // Local time in default ISO format
    return localDateTime;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          '@media (max-width: 767px)': {
            flexGrow: 1,       
          },
        }}
      >
        <CalenderDialog
          ref={dateTimeRef}
          open={openCalender}
          publishedDate={publishedDate}
          handleClose={handleCloseCalender}
          handleOk={handleCalenderOk}
        ></CalenderDialog>

        <Tooltip title="Preview">
          <IconButton
            onClick={handlePreviewOpen}
            style={{ cursor: "pointer" }}
            size="small"
            disableRipple
            disableFocusRipple
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>

        <Preview open={previewOpen} handleClose={handlePreviewClose} />
        <Tooltip title="Draft">
          <IconButton
            onClick={handleCreateArticle}
            style={{ cursor: "pointer" }}
            size="small"
            disableRipple
            disableFocusRipple
          >
            <DraftsIcon />
          </IconButton>
        </Tooltip>

        {article.status === CONTENT_STATES.PUBLISHED ? (
          <Button
            size="small"
            variant="contained"
            onClick={handlePublishArticle}
            sx={{
              '@media (max-width: 767px)': {
                marginLeft: 'auto',        
              },
            }}
          >
            Publish
          </Button>
        ) : (
          <SplitButton
            status={article.status}
            handleDraft={handleCreateArticle}
            handlePublishArticle={handlePublishArticle}
            setOpenCalender={setOpenCalender}
          ></SplitButton>
        )}
      </Box>
    </>
  );
};

export default ResourceButton;

import React, { useEffect, useState } from "react";
import { TextField, FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../store/actions/nearByActions";
import { localizedProductTitleSelector } from "../../../store/reducers/ProductSelector";
import { CommentBankSharp, LanSharp } from "@mui/icons-material";
import { SESSION_STORAGE } from "../../../constants/GeneralConstant";

const NearByHeader = () => {
  const dispatch = useDispatch();
  const [lang, setLang] = useState("EN");
  const language = useSelector((state) => state.nearBy.language);

  const handleLanguage = (event) => {
    const language = event.target.value;
    dispatch(setLanguage(language));
    sessionStorage.setItem(SESSION_STORAGE.SPEC_LANGUAGE, language);
  };

  useEffect(() => {
    setLang(language);
  }, [language]);
  //   const handleTitleChange = (e) => {
  //     const text = e.target.value;
  //     const trimmedText = text.trimStart(); // Remove only leading spaces
  //     dispatch(setTitle(trimmedText));
  //   };

  return (
    <>
      <Box
        sx={{
            display: "flex",
            mb: 1.5,
            pt: 0.75,
            pb: 2,
            gap: 1,
            borderBottom: '1px solid #E1E1E1',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >

        <Typography variant="h5">
          Add Nearby
        </Typography>

        <Box
          sx={{
            display: "flex",
            width: '100%',
            gap: "12px",
            flexGrow: 1,
            justifyContent: "end", 
            '@media (max-width: 820px)': {
              // flexGrow: 1,
              // justifyContent: "end",          
            },
            '@media (max-width: 767px)': {
              flexWrap: "wrap",      
            },
          }}
        >

                      {/* <TextField
          id="title"
          size="small"
          className="title"
          required
          value={productTitle}
          variant="outlined"
          onChange={handleTitleChange}
          fullWidth
          // sx={{ flex: '1 1 50%', mr: '0.01rem' }}
          sx={{ mr: '0.01rem' }}
          placeholder={`Enter title for your product here..`}
        /> */}

        <FormControl
          size="small"
          className="selector"
          sx={{ 
            minWidth: "10rem",
            '@media (max-width: 767px)': {
                minWidth: "100%", 
              }, 
          }}
        >
          <Select
            onChange={handleLanguage}
            value={lang}
            displayEmpty
            name="language"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"EN"}>English</MenuItem>
            <MenuItem value={"LU"}>Luganda</MenuItem>
            <MenuItem value={"RY"}>Runyakitara</MenuItem>
          </Select>
        </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default NearByHeader;

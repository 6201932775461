/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFilteredEndCreationDate,
  setFilteredStartCreationDate,
} from '../../../store/actions/filter';

const FilteredCreationDate = ({
  filteredData,
  setFilteredData,
  setCreateDateError,
}) => {

  const sdate = useSelector((state) => state.filter.startCreationDate);
  const edate = useSelector((state) => state.filter.endCreationDate);

  useEffect(() => {
    !!sdate && handleStartDate(sdate);
  }, [sdate])

  useEffect(() => {
    !!edate && handleEndDate(edate);
  }, [edate])

  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [startDateTime, setStartDateTime] = useState(sdate ? sdate : null);
  const [endDateTime, setEndDateTime] = useState(edate ? edate : null);

  const handleStartDate = (sdate) => {
    dispatch(setFilteredStartCreationDate(sdate));
    const isoDate = dayjs(sdate).format('YYYY-MM-DDT00:00:59.SSS[Z]');
    setFilteredData((prevData) => ({
      ...prevData,
      startCreateDate: isoDate,
    }));
    setStartDateTime(sdate);
    const end = new Date(sdate + 24 * 60 * 60 * 1000);
    handleEndDate(end);
  };

  const handleEndDate = (edate) => {
    dispatch(setFilteredEndCreationDate(edate));
    const isoDate = dayjs(edate).format('YYYY-MM-DDT23:59:59.SSS[Z]');
    setFilteredData((prevData) => ({
      ...prevData,
      endCreateDate: isoDate,
    }));
    setEndDateTime(edate);
    validateDate(startDateTime, edate);
  };

  const validateDate = (start, end) => {
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    if (!start && endDate) {
      setError('Start date is mandatory.');
      setCreateDateError('Start date is mandatory.');
    } else if (startDate && endDate && startDate > endDate) {
      setError('Invalid Date');
      setCreateDateError('Invalid Date');
    } else {
      setError('');
      setCreateDateError('');
    }
  };

  useEffect(() => {
    validateDate(startDateTime, endDateTime);
  }, [startDateTime, endDateTime, validateDate]);

  return (
    <Box style={{ margin: '1em' }}>
      <Typography variant="h6" sx={{fontSize: '14px'}}>Creation Date</Typography>
      <Box sx={{ display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="Start Date"
                value={startDateTime ? dayjs(startDateTime) : null}
                onChange={handleStartDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ flexGrow: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="End Date"
                value={endDateTime ? dayjs(endDateTime) : null}
                onChange={handleEndDate}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      </Box>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </Box>
  );
};

export default FilteredCreationDate;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import { WithHeader } from "../../common/WithHeader";
import BlobService from "../../../services/blob-service";
import {
  deleteAttachment,
  setAttachment,
} from "../../../store/actions/currentProduct";
import FileUploader from "../../know-your-ticks/Editing/FileUploader";
import Attachments from "./Attachments";
import { getFilteredAttachments } from "../../../store/reducers/ProductSelector";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
const MAX_IMAGE_COUNT = 5;

const MultiImageUploader = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const attachments = useSelector(getFilteredAttachments);

  const handleFileSelect = (e) => {
    // const selectedFiles = Array.from(e.target.files).slice(0, MAX_IMAGE_COUNT);
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > MAX_IMAGE_COUNT) {
      setError("You can only select a maximum of 5 files.");
      return;
    }
    if (selectedFiles.length + attachments.length <= MAX_IMAGE_COUNT) {
      let isError = false;

      const newFiles = selectedFiles
        .map((file) => {
          if (
            file.size <= MAX_FILE_SIZE &&
            (file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "image/jpg" ||
              file.type === "application/pdf")
          ) {
            setError("");
            return {
              file,
              progress: 0,
              size: file.size,
              filename: BlobService.generateMultipleFileName(file),
            };
          } else {
            isError = true;
            setError(
              file.size > MAX_FILE_SIZE
                ? "Please select a file within 5 MB."
                : "Invalid file format.Please select a JPG/JPEG/PNG/PDF file."
            );
            return null;
          }
        })
        .filter(Boolean);

      if (!isError) {
        setFiles([...files, ...newFiles]);
        const uploadPromises = newFiles.map((newFile, index) => {
          const isPdf = newFile.filename.endsWith(".pdf");
          const uploadMethod = isPdf
            ? BlobService.uploadPdf
            : BlobService.uploadImage;

          return uploadMethod(newFile.filename, newFile.file, (progress) => {
            setFiles((prevFiles) =>
              prevFiles.map((f, i) =>
                i === files.length + index ? { ...f, progress } : f
              )
            );
          }).then((response) => {
            const uploadedFile = {
              filename: newFile.filename,
              containerName: response.containerName,
            };
            dispatch(setAttachment(newFile.filename, newFile));
            setUploadedFiles([...uploadedFiles, uploadedFile]);
            const imageUrl = newFile.filename;
            setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]);
          });
        });

        Promise.all(uploadPromises)
          .then(() => {
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setError("Error uploading files.");
          });
      }
    } else {
      setError("You can only select a maximum of 5 files.");
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFiles = Array.from(e.dataTransfer.files);
    if (selectedFiles.length > MAX_IMAGE_COUNT) {
      setError("You can only select a maximum of 5 files.");
      return;
    }
    if (selectedFiles.length + attachments.length <= MAX_IMAGE_COUNT) {
      let isError = false;

      const newFiles = selectedFiles
        .map((file) => {
          if (
            file.size <= MAX_FILE_SIZE &&
            (file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "image/jpg" ||
              file.type === "application/pdf")
          ) {
            setError("");
            return {
              file,
              progress: 0,
              size: file.size,
              filename: BlobService.generateMultipleFileName(file),
            };
          } else {
            isError = true;
            setError(
              file.size > MAX_FILE_SIZE
                ? "Please select a file within 5 MB."
                : "Invalid file format.Please select a JPG/JPEG/PNG/PDF file."
            );
            return null;
          }
        })
        .filter(Boolean);

      if (!isError) {
        setFiles([...files, ...newFiles]);

        const uploadPromises = newFiles.map((newFile, index) => {
          const isPdf = newFile.filename.endsWith(".pdf");
          const uploadMethod = isPdf
            ? BlobService.uploadPdf
            : BlobService.uploadImage;

          return uploadMethod(newFile.filename, newFile.file, (progress) => {
            setFiles((prevFiles) =>
              prevFiles.map((f, i) =>
                i === files.length + index ? { ...f, progress } : f
              )
            );
          }).then((response) => {
            const uploadedFile = {
              filename: newFile.filename,
              containerName: response.containerName,
            };
            dispatch(setAttachment(newFile.filename, newFile));
            setUploadedFiles([...uploadedFiles, uploadedFile]);
            const imageUrl = newFile.filename;
            setImageUrls((prevImageUrls) => [...prevImageUrls, imageUrl]);
          });
        });

        Promise.all(uploadPromises)
          .then(() => {
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setError("Error uploading files.");
          });
      }
    } else {
      setError("You can only select a maximum of 5 files.");
    }
  };

  const deleteFile = (filename, index) => {
    setError(null);
    // BlobService.deleteImage(filename).then(() => {
    const newFiles = files.filter((file) => file.filename !== filename);
    setFiles(newFiles);
    const newUploadedFiles = uploadedFiles.filter(
      (file) => file.filename !== filename
    );
    setUploadedFiles(newUploadedFiles);
    const newImageUrls = imageUrls.filter((imageUrl) => imageUrl !== filename);
    dispatch(deleteAttachment(index));
    setImageUrls(newImageUrls);
    // });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          borderColor: "grey.500",
          height: 330,
          position: "relative",
          m: 5,
        }}
        justifyContent="center"
        textAlign="center"
      >
        {attachments?.length === 5 && (
          <Typography sx={{ color: "red" }}>
            You can only select a maximum of 5 files.
          </Typography>
        )}

        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          disabled={attachments?.length === 5}
        >
          <input
            hidden
            accept=".jpeg, .png, .jpg .pdf"
            type="file"
            onChange={handleFileSelect}
            multiple
            disabled={attachments?.length === 5}
          />
          <DriveFolderUploadRoundedIcon
            style={{ fontSize: "4em", color: "#D9D9D9" }}
          />{" "}
        </IconButton>
        {attachments?.length === 5
          ? null
          : error && <Box color="red">{error}</Box>}
        <Box
          display="flex"
          marginTop="1rem"
          marginBottom="1rem"
          padding="3rem"
          border="dashed 1px #ccc"
          alignItems={"center"}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Typography variant="caption">
            drag and drop the image here or upload manually. <br />
          </Typography>
        </Box>
        <Typography variant="caption">
          Max File Size: 5 MB Allowed formats: JPEG/JPG/PNG/PDF
        </Typography>
      </Box>
      <FileUploader files={files} />
      <Attachments attachments={attachments} deleteFile={deleteFile} />
    </>
  );
};
export default WithHeader(MultiImageUploader, "Attachments");

import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import CategorySelector from "../../common/CategorySelector";
// import Score from '../../common/Score';
import Recommendation from "../../common/Recommendation";
import VideoUpload from "../../common/VideoUpload";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearCurrentRescource,
  retrieveRescourcesbyId,
  setLearningResource,
} from "../../../store/actions/currentArticle";
import ResourceButton from "../../common/ResourceButton";
import { URL } from "../../../constants/URL";
import { useSelector } from "react-redux";
import { setLanguage } from "../../../store/actions/currentArticle";
import VideoUrlField from "../../common/UrlField";
import AuthorDetail from "../../articles/create-article/AuthorDetail";
import PublisherDetails from "../../common/publisherDetails";
import {
  retrieveLearningRecommendation,
  retrieveLearningResource,
} from "../../../store/actions/learningResource";
import { Box } from "@mui/material";

const RECOMMENDED_ASPECT_RATIO = 1 / 1;
const TOLERANCE = 0;
const recommendedSizeMessage =
  "Best results with a 1:1 image, like (400 x 600, 600 x 900).";

const Training = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const { id } = useParams();
  const lang = useSelector((state) => state.article?.language);

  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );

  useEffect(() => {
    dispatch(retrieveLearningRecommendation());

    if (id) {
      dispatch(retrieveRescourcesbyId(id));
    } else {
      dispatch(clearCurrentRescource());
      dispatch(setLearningResource("Training"));
    }
  }, [dispatch, id]);
  return (
    <Box>
      <Header
        setLanguage={setLanguage}
        data={resource}
        error={error}
        setError={setError}
      />
      <VideoUpload
        requiredAspectRatio={RECOMMENDED_ASPECT_RATIO}
        TOLERANCE={TOLERANCE}
        recommendedSize={recommendedSizeMessage}
      />
      <h2 style={{ textAlign: "center", marginBottom: '20px'}}>OR</h2>
      <Box sx={{ marginBottom: '20px'}}>
      <VideoUrlField />
      </Box>
      {/* <Score /> */}
      <Box sx={{ marginBottom: '20px'}}>
      <CategorySelector />
      </Box>
      <Box sx={{ marginBottom: '20px'}}>
      <PublisherDetails />
      </Box>
      <Box sx={{ marginBottom: '20px'}}>
      <Recommendation resource={"Training"} lang={lang} />
      </Box>
      {/* <ResourceButton
        addResourceUrl={URL.RESOURCE}
        ListingUrl={URL.LEARNING_RESOURCE_LISTING}
        error={error}
      /> */}
      
    </Box>
  );
};

export default Training;

import React, { useRef, useState } from "react";
import { Button } from "@mui/material";
import Preview from "./preview/Preview";
import { useDispatch, useSelector } from "react-redux";
import {
  eventCoverImageSelector,
  eventEndDateSelector,
  eventLabelSelector,
  eventStartDateSelector,
  getFilteredCategories,
  localizedAddressSelector,
  localizedAudienceSelector,
  localizedEventDetailsSelector,
  localizedTitleSelector,
} from "../../store/reducers/selector";
import { toast } from "react-toastify";
import { validateEvent } from "../validations/validateEvent";
import PreviewIcon from "@mui/icons-material/Preview";
import DraftsIcon from "@mui/icons-material/Drafts";
import { IconButton, Tooltip } from "@mui/material";
import {
  getEventsData,
  createEvent,
  editEvent,
} from "../../store/actions/event";
import { useNavigate, useParams } from "react-router-dom";
import usePrompt from "../hook/usePrompt";
import CalenderDialog from "../common/CalenderDialog";
import SplitButton from "../common/SplitButtons";
import { CONTENT_STATES, EVENT_STATUS } from "../../constants/GeneralConstant";
import dayjs from "dayjs";

const EventButtons = ({ dateError }) => {
  const navigate = useNavigate();
  const event = useSelector((state) => state.currentEvent);
  const { id } = useParams();
  const dispatch = useDispatch();
  const eventTitle = useSelector(localizedTitleSelector);

  const [previewOpen, setPreviewOpen] = useState(false);
  const eventCategory = useSelector(getFilteredCategories);
  const eventAud = useSelector(localizedAudienceSelector);
  const eventDetail = useSelector(localizedEventDetailsSelector);
  const eventStart = useSelector(eventStartDateSelector);
  const eventEnd = useSelector(eventEndDateSelector);
  const eventCoverImage = useSelector(eventCoverImageSelector);
  const [isPublished, setIsPublished] = useState(true);
  const eventAddress = useSelector(localizedAddressSelector);
  const eventLang = useSelector((state) => state.currentEvent.eventLanguage);
  const {
    // eventLang,
    eventLabel,
    eventStartDateTime,
    eventEndDateTime,
    eventLink,
    notificationDatetime,
    notificationInterval,
    externalOrganizer,
  } = useSelector((state) => state.currentEvent);
  const [openCalender, setOpenCalender] = useState(null);
  const dateTimeRef = React.useRef(null);

  const handlePreviewOpen = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const prepareEventPayload = (event) => {
    let payload = {
      title: event.title,
      status: event.status,
      eventLanguage: event.eventLanguage,
      address: event.address,
      targetAudience: event.targetAudience,
      eventDetails: event.eventDetails,
      label: event.eventLabel,
      coverImage: event.coverImage,
      isAuthRequired: true,
      eventStartDateTime: event.eventStartDateTime,
      eventEndDateTime: event.eventEndDateTime,
      categoryIds: event.categoryIds,
      organizerId: event.organizerId,
      attachments: event.attachments,
      timezones: "",
      eventVideo: "string",
      eventLink: event.eventLink,
      notificationDatetime: event.notificationDatetime,
      notificationInterval: Array.isArray(event.notificationInterval)
        ? event.notificationInterval
        : [event.notificationInterval],
      metaData: {},
      externalOrganizer: event.externalOrganizer,
      approvalStatus: event.approvalStatus,
    };
    if (event.externalOrganizer) {
      delete payload.organizerId;
    }
    return payload;
  };

  const handleSaveResource = async (status, approvalStatus) => {
    const validationError = validateEvent(
      event,
      eventTitle,
      eventAddress,
      eventCategory,
      eventAud,
      eventDetail,
      eventStart,
      eventEnd,
      dateError,
      eventLang,
      eventLabel,
      eventStartDateTime,
      eventEndDateTime,
      eventLink,
      notificationDatetime,
      notificationInterval,
      externalOrganizer
    );

    if (validationError) {
      toast.error(validationError);
      return;
    }
    event.status = status;
    event.approvalStatus = approvalStatus;
    if (event.status === CONTENT_STATES.SCHEDULED) {
      event.publishedAt = combinedDateTime();
    }

    const payload = prepareEventPayload(event);

    try {
      if (id) {
        await dispatch(editEvent(id, payload)).then(() => {
          dispatch(getEventsData());
        });
        toast.success(`Event edited successfully!`);
      } else {
        await dispatch(createEvent(payload)).then(() => {
          dispatch(getEventsData());
        });
        toast.success(`Event successfully ${status.toLowerCase()}!`);
      }
      navigate(`/events`);
    } catch {
      toast.error(`Failed to ${id ? "edit" : "create"} Event!`);
    }
  };

  const handleDraftEvent = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.DRAFT, EVENT_STATUS.APPROVED);
  };

  const handlePublishEvent = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.PUBLISHED, EVENT_STATUS.APPROVED);
  };

  const isBlocking = () => {
    return (eventTitle || eventCoverImage || eventAddress) && isPublished;
  };

  usePrompt("Are you sure you want to leave?", isBlocking());

  const handleCloseCalender = () => {
    setOpenCalender(false);
  };

  const handleCalenderOk = () => {
    // setButtonStatus(STATUS.SCHEDULED);
    setOpenCalender(false);
    handleScheduleEvent();
  };

  const handleScheduleEvent = () => {
    setIsPublished(false);
    handleSaveResource(CONTENT_STATES.SCHEDULED);
  };

  const combinedDateTime = () => {
    const selectedDate = dateTimeRef.current.getSelectedDate();
    const selectedTime = dateTimeRef.current.getSelectedTime();

    let combinedDateTime = dayjs(selectedDate);
    if (selectedDate && selectedTime) {
      // Combine date and time
      combinedDateTime = dayjs(selectedDate)
        .set("hour", dayjs(selectedTime).hour())
        .set("minute", dayjs(selectedTime).minute())
        .set("second", dayjs(selectedTime).second())
        .set("millisecond", dayjs(selectedTime).millisecond());

      // Format as ISO 8601 string
    }
    const localDateTime = combinedDateTime.format(); // Local time in default ISO format
    return localDateTime;
  };

  return (
    <>
      {/* <CalenderDialog
        ref={dateTimeRef}
        open={openCalender}
        handleClose={handleCloseCalender}
        handleOk={handleCalenderOk}
      ></CalenderDialog> */}
      <Preview open={previewOpen} handleClose={handlePreviewClose} />
      <div style={{ display: "flex", justifyContent: "center", margin: "0 0" }}>
        <Button
          size="small"
          variant="contained"
          onClick={handlePreviewOpen}
          style={{ margin: 10 }}
        >
          Preview
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleDraftEvent}
          style={{ margin: 10 }}
        >
          Draft
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handlePublishEvent}
          style={{ margin: 10 }}
        >
          Publish
        </Button>
        {/* <Tooltip title="Preview">
          <IconButton
            onClick={handlePreviewOpen}
            style={{ margin: 10, cursor: "pointer" }}
            size="small"
            disableRipple
            disableFocusRipple
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>
       
        <Tooltip title="Draft">
          <IconButton
            onClick={handleCreateEvent}
            style={{ margin: 10, cursor: "pointer" }}
            size="small"
            disableRipple
            disableFocusRipple
          >
            <DraftsIcon />
          </IconButton>
        </Tooltip>
        {event.status === CONTENT_STATES.PUBLISHED ? (
          <Button
            size="small"
            variant="contained"
            onClick={handlePublishEvent}
            style={{ margin: 10 }}
          >
            Publish
          </Button>
        ) : (
          <SplitButton
            status={event.status}
            handleDraft={handleCreateEvent}
            handlePublishArticle={handlePublishEvent}
            setOpenCalender={setOpenCalender}
          ></SplitButton>
        )} */}
      </div>
    </>
  );
};

export default EventButtons;

import { getFormLanguage } from "../../component/common/GeneralHelper";
import { LANGUAGE_KEY } from "../../constants/GeneralConstant";
import {
  SET_ADDRESS,
  SET_PHONE,
  SET_DISTRICT,
  SET_INCHARGE,
  SET_FULL_NAME,
  GET_NEARBY_SUCCESS,
  GET_NEARBY_FAILURE,
  DELETE_NEARBY_SUCCESS,
  DELETE_NEARBY_FAILURE,
  CREATE_NEARBY_SUCCESS,
  CREATE_NEARBY_FAILURE,
  EDIT_NEARBY_SUCCESS,
  EDIT_NEARBY_FAILURE,
  RETRIEVE_NEARBY_SUCCESS,
  RETRIEVE_NEARBY_FAILURE,
  SET_LANGUAGE,
  TOGGLE_GATING_CONTENT_SUCCESS,
  TOGGLE_GATING_CONTENT_FAILURE,
  SET_SPECIES,
  SET_CONTENT,
  SET_CONTENT_SPECIES,
  GET_NEARBY_SPECIES_SUCCESS,
} from "../actions/type";

const initialState = {
  language: "EN",
  metaData: { language: "EN" },
  fullname: { EN: "", LU: "", RY: "" },
  addressLine1: { EN: "", LU: "", RY: "" },
  isAuthRequired: false,
  phone: "",
  fullTimeIncharge: { EN: "", LU: "", RY: "" },
  district: { EN: "", LU: "", RY: "" },
  species: {},
  contentSpecies: [],
  nearBySpecies: {},
};

export const nearByReducer = (state = initialState, action) => {
  let language_key = state.language;
  switch (action.type) {
    case SET_ADDRESS:
      return {
        ...state,
        addressLine1: {
          ...state.addressLine1,
          [language_key]: action.payload,
        },
      };
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_FULL_NAME:
      return {
        ...state,
        fullname: {
          ...state.fullname,
          [language_key]: action.payload,
        },
      };
    case SET_SPECIES:
      return {
        ...state,
        species: { ...state.species, [language_key]: action.payload },
      };
    case SET_DISTRICT:
      return {
        ...state,
        district: {
          ...state.district,
          [language_key]: action.payload,
        },
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        metaData: {
          language: action.payload,
        },
      };
    case SET_INCHARGE:
      return {
        ...state,
        fullTimeIncharge: {
          ...state.fullTimeIncharge,
          [language_key]: action.payload,
        },
      };
    case GET_NEARBY_SPECIES_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else
        return {
          ...state,
          nearBySpecies: action.payload,
          loading: false,
        };
    case GET_NEARBY_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else
        return {
          ...state,
          nearBy: action.payload,
          loading: false,
        };
    case GET_NEARBY_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case DELETE_NEARBY_SUCCESS:
      const updatedNearBy = state.nearBy.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        nearBy: updatedNearBy,
        loading: false,
        error: null,
      };
    case DELETE_NEARBY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TOGGLE_GATING_CONTENT_SUCCESS:
      return {
        ...state,
        isAuthRequired: action.payload.isAuthRequired,
      };
    case TOGGLE_GATING_CONTENT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_NEARBY_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else {
        return action.payload;
      }
      return action.payload;
    case CREATE_NEARBY_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case EDIT_NEARBY_SUCCESS:
      return { nearBy: action.payload };
    case EDIT_NEARBY_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case RETRIEVE_NEARBY_SUCCESS:
      if (!action.payload) {
        return { ...initialState };
      } else {
        const lang = getFormLanguage(action.payload?.fullname);
        return {
          ...state,
          language: lang || LANGUAGE_KEY.ENGLISH,
          fullname: action.payload.fullname,
          addressLine1: action.payload.addressLine1,
          phone: action.payload.phone,
          fullTimeIncharge: action.payload.fullTimeIncharge,
          isAuthRequired: action.payload.isAuthRequired,
          district: action.payload.district,
          species: action.payload.species,
          loading: false,
        };
      }
    case RETRIEVE_NEARBY_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case SET_CONTENT_SPECIES:
      return { ...state, contentSpecies: action.payload };
    default:
      return state;
  }
};

export default nearByReducer;

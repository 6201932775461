import React, { useState } from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../common/WithHeader";
import {
  eventLinkSelector,
  localizedAddressSelector,
} from "../../store/reducers/selector";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, setEventLink } from "../../store/actions/currentEvent";
import { validate } from "uuid";
import { validateUrl } from "../common/GeneralHelper";
import { MEETING_LINK_REGEX } from "../../constants/GeneralConstant";

const Link = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);

  const eventLink = useSelector(eventLinkSelector);

  const HandleAddress = (event) => {
    setError(false);
    const text = event.target.value;
    if (!validateUrl(MEETING_LINK_REGEX)(text)) {
      setError(true);
    }
    const trimmedText = text.trimStart(); // Remove only leading spaces
    dispatch(setEventLink(trimmedText));
  };

  return (
    <div>
      <TextField
        placeholder="Enter the Link of your event here.."
        multiline
        rows={2}
        value={eventLink || ""}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // set borderRadius to 0
          },
        }}
        onChange={HandleAddress}
        // value={localizedContent}
        variant="outlined"
        error={error}
        helperText={eventLink.length > 0 && error && "Link is not valid"}
      />
    </div>
  );
};

export default WithHeader(Link, "Link");

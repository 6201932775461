// External imports
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import { toast } from "react-toastify";

// Internal imports
import FormValidation from "../common/FormValidations";
import { resendOTPToken, verifyOTP } from "../../store/actions/users";
import { formatEmailSameLength } from "../common/GeneralHelper";
import { CODE_ATTEMPT } from "../../constants/GeneralConstant";
import "./style.scss";

function VerifyOTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email") ?? "";

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [resendMessage, setResendMessage] = useState("");

  const dispatch = useDispatch();
  let count = localStorage.getItem(CODE_ATTEMPT) ?? 0;

  useEffect(() => {
    return () => {
      localStorage.removeItem(CODE_ATTEMPT);
    };
  }, []);

  useEffect(() => {
    if (!location.state) {
      location.state = { email: email };
    }
  }, [location, email]);

  const formik = useFormik({
    initialValues: {
      token: "",
    },
    validationSchema: FormValidation.SecurityCodeSchema,
    onSubmit: (values, actions) => {
      setLoading(true);
      const payload = { email, code: values.token.toString() };

      dispatch(verifyOTP(payload))
        .then(() => {
          toast.success("Successfully Verified");
          navigate("/login");
        })
        .catch((e) => {
          toast.error(e.response.data.message);
          setLoading(false);
          localStorage.setItem(CODE_ATTEMPT, ++count);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const handleResendLink = () => {
    dispatch(resendOTPToken({ email }))
      .then(() => {
        setResendMessage("A new token has been sent to your email.");
        localStorage.setItem(CODE_ATTEMPT, 0);
      })
      .catch((e) => {
        setMessage(e.response.data.message);
        toast.error(e);
      });
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      {/* Right: OTP Form */}
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          padding: 4,
        }}
      >
        <Box sx={{ px: 3 }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Box
                component="img"
                src="/img/elanco-logo.svg"
                alt="Elanco"
                sx={{
                  height: "80px",
                  objectFit: "cover",
                  mb: 3,
                }}
              />
              <Typography
                color="textPrimary"
                variant="h4"
                sx={{ mb: 1, fontSize: "1.5rem" }}
              >
                Verify your account
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                We have sent a verification code to{" "}
                {formatEmailSameLength(email)}. Check your inbox or junk folder.
              </Typography>
            </Box>

            <TextField
              error={Boolean(formik.touched.token && formik.errors.token)}
              fullWidth
              helperText={formik.touched.token && formik.errors.token}
              label="Security Token"
              margin="normal"
              name="token"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.token}
              variant="outlined"
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                },
                style: { WebkitAppearance: "none", MozAppearance: "textfield" },
              }}
            />

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={
                  formik.isSubmitting ||
                  localStorage.getItem(CODE_ATTEMPT) >= "3"
                }
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loading && (
                  <CircularProgress size={20} sx={{ marginRight: 1 }} />
                )}
                Continue
              </Button>

              {message && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {message}
                </Alert>
              )}

              {localStorage.getItem(CODE_ATTEMPT) >= "3" && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  You have entered the wrong code multiple times. Try resending
                  the code.
                </Alert>
              )}
            </Box>
          </form>

          <Typography sx={{ mt: 1 }}>
            <Link
              sx={{
                textDecoration: "none",
                fontSize: "14px",
                "&:hover": { textDecoration: "underline" },
              }}
              href="#"
              onClick={handleResendLink}
            >
              Resend Code
            </Link>
          </Typography>

          {resendMessage && (
            <Typography sx={{ mt: 1 }} color="textSecondary">
              {resendMessage}
            </Typography>
          )}
        </Box>
      </Container>

      <Box
        className="hero-img"
        sx={{
          flex: 1,
          backgroundImage: "url(/img/elanco-admin-cattle.jpg)",
          backgroundSize: "cover",
          display: { xs: 'none', sm: 'block', md: 'block' },
        }}
      />
    </Box>
  );
}

export default VerifyOTP;

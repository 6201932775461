import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ContentHeader from "../../../component/common/ContentHeader";
import UserFilter from "../../../component/Filter/User/FilterBox";
import { setFilteredQueryParams } from "../../../store/actions/filter";
import {
  deleteUser,
  getAllAdmins,
  updateStatus,
} from "../../../store/actions/users";
import { formatDate } from "../../../component/common/DateFormatter";
import { Tooltip } from "@mui/material";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DeleteConfirmationDialog from "../../../component/common/DeleteConfirmationDialog";
import { toast } from "react-toastify";
import ServerSideTable from "./ServerTable";
import { URL } from "../../../constants/URL";

const UserList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { params: filterParams } = useSelector((state) => state.filter);

  const { USER_EDIT } = URL;
  const { result, meta } = useSelector((state) => state.users.list);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch, id]);

  const handleClearSearchQuery = (event) => {
    setSearchQuery("");
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [listData, setListData] = useState([]);
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let payload = {
        ...filterParams,
        search: searchQuery,
        page: page ? page : 1,
        perPage: pageSize,
        orderBy: sortModel?.[0]?.field,
        orderAt: sortModel?.[0]?.sort,
      };
      dispatch(setFilteredQueryParams(payload));
      dispatch(getAllAdmins(payload));
    } catch (error) {
      setRows([]); // Reset rows on error
      setRowCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortModel, searchQuery]);

  useEffect(() => {
    setRows(result || []); // Ensure rows is always an array
    setRowCount(meta?.total || 0); // Ensure totalCount is always a number
    setListData(result);
  }, [result]);

  const getPermissionsLabel = (permissions) => {
    let data = permissions.map((m) => m.permission).join(", ");
    if (data.length > 40) {
      return data.slice(0, 40) + "...";
    }
    return data || "N/A";
  };

  const getUpdatedDate = (item) => {
    return item.updatedAt
      ? `${formatDate(item.updatedAt).date} | ${
          formatDate(item.updatedAt).time
        }`
      : `${formatDate(item.createdAt).date} | ${
          formatDate(item.createdAt).time
        }`;
  };

  const columns = [
    {
      field: "firstname",
      headerName: "Name",
      width: 150,
      renderCell: (params) => {
        return params.row.firstname + " " + params.row.lastname;
      },
    },
    {
      field: "permissions",
      headerName: "Permissions",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return getPermissionsLabel(params.row.permissions);
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      flex: 1,
      renderCell: (params) => {
        return getUpdatedDate(params.row);
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        params.sortDirection && (
          <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
        );
        const HandleActivation = (params) => {
          setLoading(true);
          dispatch(updateStatus(params.row))
            .then((res) => {
              if (!!res.data.isActive) {
                toast.success("Successfully activated!");
              } else {
                toast.success("Successfully deactivated!");
              }
            })
            .catch(() => {
              toast.error("Failed to activate/deactivate user");
            })
            .finally(() => {
              setLoading(false);
              fetchData();
            });
        };

        let activation;
        if (!!params.row.isActive) {
          activation = (
            <Tooltip title="deactivate" position="top">
              <VisibilityOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        } else {
          activation = (
            <Tooltip title="activate">
              <VisibilityOffOutlinedIcon
                onClick={() => HandleActivation(params)}
              />
            </Tooltip>
          );
        }
        const [open, setOpen] = useState(false);

        const handleDelete = (params) => {
          setLoading(true);
          dispatch(deleteUser(params.row))
            .then(() => {
              toast.success("Successfully deleted!");
              // Refresh the list
              fetchData();
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Failed to delete resource!");
            });
        };

        return (
          <>
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                onClick={() => {
                  navigate(USER_EDIT.replace(":id", params.id));
                }}
              />
            </Tooltip>
            {activation}
            <Tooltip title="Delete">
              <DeleteOutlineOutlinedIcon onClick={() => setOpen(true)} />
            </Tooltip>
            {open && (
              <DeleteConfirmationDialog
                open={open}
                onClose={() => setOpen(false)}
                onDelete={() => handleDelete(params)}
              />
            )}
          </>
        );
      },
    },
  ];

  const onPageChange = (newPage) => {
    let filters = { ...filterParams, page: newPage + 1 };
    dispatch(setFilteredQueryParams(filters));
    setPage(newPage + 1);
  };

  const onPageSizeChange = (newPageSize) => {
    let filters = { ...filterParams, limit: newPageSize };
    dispatch(setFilteredQueryParams(filters));
    setPageSize(newPageSize);
  };
  const onSortModelChange = (newSortModel) => setSortModel(newSortModel);
  return (
    <>
      <ContentHeader
        header="Sub Admins"
        count={listData?.meta?.total || 0}
        route="/users/add"
        searchQuery={searchQuery}
        onSearchQueryChange={handleSearchQueryChange}
        onClearSearchQuery={handleClearSearchQuery}
        searchPlaceholder="Search by Sub Admins"
        filterBox={UserFilter}
      />
      <ServerSideTable
        searchQuery={searchQuery}
        columns={columns}
        rows={rows}
        rowCount={rowCount}
        loading={loading}
        page={page}
        pageSize={pageSize}
        listData={listData}
        setSortModel={setSortModel}
        filterParams={filterParams}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        fetchData={fetchData}
        sortModel={sortModel}
      />
    </>
  );
};

export default UserList;

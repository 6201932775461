import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getScoreData, setScoreData } from "../../store/actions/scoreCard";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { PREMIUM_SCORECARD_ROWS, SCORECARD_ROWS } from "./Constants";



const CustomDataGrid = styled(DataGrid)(() => ({
  // "& .MuiDataGrid-cell": {
  //   border: "1px solid grey",
  // },
  // "& .MuiDataGrid-cell:first-of-type": {
  //   borderLeft: "none",
  // },
  // "& .MuiDataGrid-cell:last-child": {
  //   borderRight: "none",
  // },
  // "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
  //   borderBottom: "none",
  // },
  // "& .MuiDataGrid-root": {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
}));

export default function ScoreCard(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { score } = useSelector((state) => state.score);
  const isPremium = location.pathname.includes("premium");

  useEffect(() => {
    dispatch(getScoreData());
  }, [dispatch]);

  const [rows, setRows] = useState(null);

  useEffect(() => {
    let ROWS = isPremium ? PREMIUM_SCORECARD_ROWS : SCORECARD_ROWS;
    ROWS = ROWS.map((r) => {
      return {
        ...r,
        points: getScoreByType(r.type, r.points),
      };
    });
    if (score) {
      setRows(ROWS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score, isPremium]);

  function getScoreByType(pointsType, val) {
    const scoreData = score.find((item) => {
      return item.pointsType === pointsType;
    });
    return scoreData ? scoreData.points : val;
  }

  const handleInputChange = (event, rowId) => {
    const updatedValue = parseInt(event.target.value);
    let errorMessage = "";
    if (updatedValue < 1 || updatedValue > 999) {
      errorMessage = "Value must be between 1 and 999.";
    }
    if (!updatedValue) {
      errorMessage = "Please enter a valid score.";
    }
    const updatedRows = rows.map((row) =>
      row.id === rowId
        ? { ...row, points: updatedValue, error: errorMessage }
        : row
    );
    setRows(updatedRows);
  };

  const handleButtonClick = (rowId) => {
    const row = rows.find((row) => row.id === rowId);
    if (row.error) {
      toast.error("Please correct the error before updating the score.");
      return;
    }

    dispatch(setScoreData({ pointsType: row.type, points: row.points }))
      .then(() => {
        toast.success("Updated successfully");
        dispatch(getScoreData());
      })
      .catch((error) => {
        toast.error("Failed to update score data.");
      });
  };
  const isMobile = useMediaQuery("(max-width:1024px)");
  const columns = [
    { 
      field: "pointsType", 
      headerName: "Content Type", 
      width: isMobile ? 100 : 300, // Adjust width based on screen size
      flex: isMobile ? 1 : undefined, // Allow auto sizing for mobile
    },
    {
      field: "points",
      headerName: "Current Score",
      width: isMobile ? 100 : 300, // Adjust width based on screen size
      flex: isMobile ? 1 : undefined, // Allow auto sizing for mobile
      renderCell: (params) => (
        <Box sx={{ position: "relative", width: "100%" }}>
          <input
            type="number"
            maxLength="3" // Add this attribute to restrict input to 3 digits
            style={{
              width: "100%",
              border: params.row.error ? "1px solid red" : "1px solid #E1e1e1",
              outline: "none",
              background: "#fff",
              padding: "12px 14px",
              borderRadius: "6px",
            }}
            // Block the input of a full stop character
            onKeyDown={(event) => {
              if (event.key === ".") {
                event.preventDefault();
              }
            }}
            value={params.row.points}
            onChange={(event) => handleInputChange(event, params.row.id)}
          />

          {params.row.error && (
            <p
              style={{
                position: "absolute",
                bottom: "-20px",
                left: 0,
                width: "100%",
                margin: 0,
                color: "#e71616",
                fontSize: "13px",
                textAlign: "left",
              }}
            >
              {params.row.error}
            </p>
          )}
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.sortDirection && (
            <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
          )}
          <Button
            sx={{
              backgroundColor: "#146879",
              color: "#fff",
            }}
            size="sm"
            onClick={() => handleButtonClick(params.row.id)}
          >
            Update
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 1.5,
          pt: 0.75,
          pb: 2,
          borderBottom: "1px solid #E1E1E1",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        <Typography variant="h5">
          {isPremium ? "Premium Scorecard" : "Scorecard"}
        </Typography>
      </Box>
      <Box className="cs-scroll" 
        sx={{ 
          height: "calc(100vh - 120px)" ,
          '@media (max-width: 767px)': {
              height: 'calc(100vh - 220px)', // Full width for screens <= 767px
            },
        }}>
        {rows ? (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            disableColumnMenu
            rowsPerPageOptions={[5]}
            hideFooter={true}
            className="score-card"
            rowHeight={100} // Set the desired row height here (in pixels)
          />
        ) : (
          <div>Loading...</div>
        )}
      </Box>
    </>
  );
}

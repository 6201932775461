import React, { useEffect, useState } from "react";
import { WithHeader } from "../common/WithHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  setEventLabel,
  setEventNotificationDate,
  setEventNotificationFrequency,
  setEventNotificationInterval,
  setEventNotificationTime,
} from "../../store/actions/currentEvent";
import { eventEndDateSelector } from "../../store/reducers/selector";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const EventNotifications = () => {
  const dispatch = useDispatch();
  const endEvent = dayjs(useSelector(eventEndDateSelector));
  const { notificationFrequency, notificationDatetime, notificationInterval } =
    useSelector((state) => state.currentEvent);

  const [error, setError] = useState(""); // For date validation

  // Handles the change in event type
  const handleChange = (event) => {
    const optionId = event.target.value;
    dispatch(setEventNotificationFrequency(optionId));
    if (optionId === 1) {
      dispatch(setEventNotificationInterval(0));
    }
  };

  // Handles the change in reminder interval
  const handleChangeInterval = (event) => {
    const interval = event.target.value;
    dispatch(setEventNotificationInterval(interval));
  };

  // Validates and sets the start date
  const handleStartDateChange = (date) => {
    const updatedStart = dayjs(date).hour(0).minute(0).second(0);
    dispatch(
      setEventNotificationDate(
        updatedStart.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      )
    );
    validateDate(updatedStart.valueOf(), endEvent?.valueOf());
  };

  const handleStartTimeChange = (time) => {
    const updatedStart = dayjs(notificationDatetime)
      .set("hour", time.hour())
      .set("minute", time.minute());
    dispatch(
      setEventNotificationDate(
        updatedStart.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      )
    );
    validateDate(updatedStart.valueOf(), endEvent?.valueOf());
  };

  const validateDate = (start, end) => {
    if (start >= end) {
      setError("Invalid Date!. Must be before end time of event");
      // setDateError("Invalid");
    } else {
      setError("");
      // setDateError("");
    }
  };

  return (
    <Box sx={{ p: 2, minWidth: 120 }}>
      <Stack alignItems="center" direction="column" sx={{ p: "4px 0" }}>
        {/* Event Type Selection */}
        <FormControl
          sx={{
            mb: 2,
          }}
          fullWidth
        >
          <Select
            labelId="event-type-label"
            id="event-type"
            value={notificationFrequency}
            onChange={handleChange}
          >
            <MenuItem value={0}>
              Select frequency including One Time or Otherwise
            </MenuItem>
            <MenuItem value={1}>One Time</MenuItem>
            <MenuItem value={2}>Other</MenuItem>
          </Select>
        </FormControl>

        {/* Start Date and Time */}
        {notificationFrequency > 0 && (
          <>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      value={dayjs(notificationDatetime)}
                      disablePast
                      onChange={handleStartDateChange}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="Start Time"
                      value={dayjs(notificationDatetime)}
                      onChange={handleStartTimeChange}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>
            </LocalizationProvider>
            {/* Reminder Interval Selection */}
            {notificationFrequency === 2 && (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Select
                  labelId="reminder-interval-label"
                  id="reminder-interval"
                  value={notificationInterval}
                  onChange={handleChangeInterval}
                >
                  <MenuItem value={15}>15 minutes before</MenuItem>
                  <MenuItem value={60}>1 hour before</MenuItem>
                  <MenuItem value={1440}>24 hours before</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        )}

        {/* Error Message Display */}
        {error && <Box sx={{ color: "red", mt: 2 }}>{error}</Box>}
      </Stack>
    </Box>
  );
};

export default WithHeader(EventNotifications, "Event Notifications");

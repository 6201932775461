import { useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  // AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import { AccountPopover } from "./account-popover";
// import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
// import { UserCircle as UserCircleIcon } from '../icons/user-circle';
// import { Users as UsersIcon } from '../icons/users';
// import { AccountPopover } from './account-popover';

// const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
//   boxShadow: theme.shadows[3],
// }));

const drawerWidth = "100%";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,
  }),
}));
export const DashboardNavbar = (props) => {
  const { onSidebarOpen, user, ...other } = props;
  const settingsRef = useRef(null);
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  const drawerOpen = useSelector((state) => state.toolbar.drawerOpen);

  const { firstname, lastname, fullname } = user || {};

  const getInitials = () => {
    if (!!firstname && !!lastname) {
      return firstname.charAt(0) + lastname.charAt(0);
    } else if (!!firstname) {
      return firstname.slice(0, 2);
    } else if (!!lastname) {
      return lastname.slice(0, 2);
    } else if (!!fullname) {
      return fullname.slice(0, 2);
    } else {
      return "EL";
    }
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: "#fff",
        }} 
       position="fixed" 
       open={drawerOpen}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!drawerOpen && (
            <IconButton onClick={onSidebarOpen}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Notifications">
            <IconButton sx={{ ml: 1 }}>
              <Badge badgeContent={4} color="primary" variant="dot">
                <NotificationsIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
          <Avatar
            onClick={() => setOpenAccountPopover(true)}
            ref={settingsRef}
            sx={{
              cursor: "pointer",
              height: 40,
              width: 40,
              ml: 2,
            }}
            //src="/static/images/avatars/avatar_1.png"
          >
            {getInitials()}
            {/* <UserCircleIcon fontSize="small" /> */}
          </Avatar>
        </Toolbar>
      </AppBar>

      <AccountPopover
        user={user}
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
      />
    </>
  );
};

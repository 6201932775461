import {
  GET_EVENT_REQUESTS_DATA_SUCCESS,
  GET_EVENT_REQUESTS_DATA_FAILURE,
  SET_SELECTED_EVENT_REQUEST,
  REJECT_EVENT_REQUESTS_SUCCESS,
  REJECT_EVENT_REQUESTS_FAILURE,
} from "../actions/type";

const initialState = {
  list: [],
  selectedRequest: null,
  loading: false,
  error: null,
};

function eventRequestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENT_REQUESTS_DATA_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case GET_EVENT_REQUESTS_DATA_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case SET_SELECTED_EVENT_REQUEST:
      return { ...state, selectedRequest: action.payload, loading: false };
    case REJECT_EVENT_REQUESTS_SUCCESS:
      return { ...state, selectedRequest: action.payload, loading: false };
    case REJECT_EVENT_REQUESTS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default eventRequestReducer;

import {
  CLEAR_FILTER,
  SET_CREATION_END_DATE,
  SET_CREATION_START_DATE,
  SET_FILTERED_EVENT_PARAMS,
  SET_FILTERED_PARAMS,
  SET_UPDATE_END_DATE,
  SET_UPDATE_START_DATE,
} from "../actions/type";

const initialState = {
  params: {
    page: 1,
    perPage: 20,
    search: "",
  },
  eventParams: {
    page: 1,
    perPage: 20,
    searchText: "",
  },
};

function filter(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERED_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case SET_FILTERED_EVENT_PARAMS:
      return {
        ...state,
        eventParams: {
          ...state.eventParams,
          ...action.payload,
        },
      };

    case SET_CREATION_START_DATE:
      return {
        ...state,
        startCreationDate: action.payload,
      };
    case SET_CREATION_END_DATE:
      return {
        ...state,
        endCreationDate: action.payload,
      };
    case SET_UPDATE_START_DATE:
      return {
        ...state,
        startUpdateDate: action.payload,
      };
    case SET_UPDATE_END_DATE:
      return {
        ...state,
        endUpdateDate: action.payload,
      };
    case CLEAR_FILTER:
      return initialState;
    default:
      return state;
  }
}

export default filter;

import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { CONTENT_STATES } from "../../constants/GeneralConstant";

const options = [
  { id: 1, label: "Schedule" },
  { id: 2, label: "Publish Now" },
  { id: 3, label: "Cancel Schedule" },
  { id: 4, label: "Convert to Draft" },
];

export default function SplitButton({
  status,
  handlePublishArticle,
  setOpenCalender,
  handleDraft,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMainClick = () => {
    if (status === CONTENT_STATES.SCHEDULED) {
      setOpenCalender(true);
    } else {
      handlePublishArticle();
    }
  };

  const handleMenuItemClick = (item) => {
    if (item.id === 1) {
      setOpenCalender(true);
    } else if (item.id === 2) {
      handlePublishArticle();
    } else if (item.id === 3 || item.id === 4) {
      handleDraft();
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // Filter options based on status
  const filteredOptions =
    status === CONTENT_STATES.SCHEDULED
      ? options.filter((option) => option.id >= 2)
      : options.filter((option) => option.id === 1);

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        className="dual-btns"
        sx={{
          '@media (max-width: 767px)': {
            marginLeft: 'auto',        
          },
        }}
      >
        <Button className="btn-publish" onClick={handleMainClick}>
          {status === CONTENT_STATES.SCHEDULED
            ? CONTENT_STATES.SCHEDULED
            : CONTENT_STATES.PUBLISH}
        </Button>
        <Button
          size="small"
          className="btn-dd"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1000 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {filteredOptions.map((item) => (
                    <MenuItem
                      key={item.id}
                      onClick={() => handleMenuItemClick(item)}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const CustomDataGrid = ({ rows, columns, ...props }) => {
  return (
    <DataGrid
      sx={{
        height: "calc(100vh - 172px)",
        "@media (max-width: 767px)": {
          height: "calc(100vh - 272px)", // Full width for screens <= 767px
        },
      }}
      rows={rows}
      columns={columns}
      {...props}
    />
  );
};

function CustomNoRowsOverlay() {
  return (
    <Box
      className="nofound-box"
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <span className="nofound-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="gray"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          width="24"
          height="24"
        >
          <path d="M10.29 3.86L1.82 19a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-15.14a2 2 0 0 0-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <circle cx="12" cy="17" r="1"></circle>
        </svg>
      </span>
      <h4 className="title">No results found!</h4>
      <h5 className="sub-title">
        Try changing the search criteria and try again
      </h5>
    </Box>
  );
}

const ServerSideTable = ({
  columns = [],
  rows = 0,
  rowCount,
  loading,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
}) => {
  return (
    <CustomDataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      disableColumnMenu
      rowsPerPageOptions={[20]}
      paginationMode="server"
      // sortingMode="server"
      rowCount={rowCount}
      loading={loading}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      onSortModelChange={onSortModelChange}
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "updatedat", sort: "desc" }],
        },
      }}
    />
  );
};

export default ServerSideTable;

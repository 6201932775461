import React from "react";
import { TextField } from "@mui/material";
import { WithHeader } from "../common/WithHeader";
import { useDispatch, useSelector } from "react-redux";
import { localizedAudienceSelector } from "../../store/reducers/selector";
import { setTargetAudience } from "../../store/actions/currentEvent";

const TargetAudience = () => {
  const dispatch = useDispatch();
  const eventAddress = useSelector(localizedAudienceSelector);
  const HandleContent = (event) => {
    const content = event.target.value;
    const trimmedText = content.trimStart(); // Remove only leading spaces
    dispatch(setTargetAudience(trimmedText));
  };
  return (
    <div>
      <TextField
        placeholder="Enter your target audience..."
        multiline
        value={eventAddress || ""}
        rows={4}
        onChange={HandleContent}
        variant="outlined"
        inputProps={{ maxLength: 1000 }}
        sx={{ width: "100%" }}
        helperText={"Add multiple target audiences separated by commas"}
      />
    </div>
  );
};

export default WithHeader(TargetAudience, "Target Audience *");

import { TextField, FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setTitleValue } from "../../store/actions/currentArticle";
import {
  languageSelector,
  localizedTitle,
} from "../../store/reducers/selector";
import ResourceButton from "./ResourceButton";
import { URL } from "../../constants/URL";

const Title = ({ error, setError, data, setLanguage }) => {
  const dispatch = useDispatch();
  // const [error, setError] = useState(false);
  // const [lang, setLang] = useState('EN');
  const language = useSelector(languageSelector);

  const article = useSelector((state) => state.article);
  const { learningResource } = useSelector((state) => state.learningResource);
  const resource = learningResource.filter(
    (resource) => resource.learningResourceType === article.learningResourceType
  );
  const title = useSelector(localizedTitle);

  const handleTitleChange = (e) => {
    const text = e.target.value;
    const trimmedText = text.trimStart(); // Remove only leading spaces

    const isTitleDuplicate = data?.some((item) => item.title === text);
    if (isTitleDuplicate) {
      setError(true);
      dispatch(setTitleValue(trimmedText));
    } else {
      setError(false);
      dispatch(setTitleValue(trimmedText));
    }
  };
  const handleLanguage = (event) => {
    const language = event.target.value;
    // setLang(language);
    dispatch(setLanguage(language));
  };

  function getResourceTypeValue(type) {
    if (!type) {
      return "Unknown Resource Type";
    }

    switch (type) {
      case "Training":
        return "Slidecasts";
      case "Video":
        return "Video";
      case "Gallery":
        return "Gallery";
      case "Article":
        return "Article";
      default:
        return "Unknown Resource Type";
    }
  }

  const selectedLinkFromLocalStorage = sessionStorage.getItem("selectedLink");
  const placeholderValue = article?.learningResourceType
    ? `Enter title for your ${getResourceTypeValue(
        article?.learningResourceType
      )}`
    : `Enter title for your ${selectedLinkFromLocalStorage?.toLowerCase()}`;
  
    const titleValue = article?.learningResourceType
    ? `Add ${getResourceTypeValue(
        article?.learningResourceType
      )}`
    : `Add ${selectedLinkFromLocalStorage?.toLowerCase()}`;

  return (
    <>
      <Box
        sx={{
            display: "flex",
            flexWrap: "wrap", 
            mb: 1.5,
            pt: 0.75,
            pb: 2,
            borderBottom: '1px solid #E1E1E1',
            gap: 1,
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
      
        <Typography variant="h5">
          {titleValue}
        </Typography>

        <Box
          sx={{
            display: "flex",
            width: '100%',
            gap: "12px",
            '@media (max-width: 820px)': {
              flexGrow: 1,
              justifyContent: "end",          
            },
            '@media (max-width: 767px)': {
              flexWrap: "wrap",
              gap: '12px',         
            },
          }}
        >

        <TextField
          id="title"
          size="small"
          className="title"
          required
          value={title}
          variant="outlined"
          onChange={handleTitleChange}
          fullWidth
          placeholder={placeholderValue}
          error={error}
          helperText={error && "Title already exist!"}
        />

          <FormControl
            size="small"
            className="selector"
            sx={{ 
              minWidth: "10rem" ,
              '@media (max-width: 767px)': {
                minWidth: "100%",        
              },
            }}
          >
            <Select
              onChange={handleLanguage}
              value={language}
              displayEmpty
              name="language"
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"EN"}>English</MenuItem>
              <MenuItem value={"LU"}>Luganda</MenuItem>
              <MenuItem value={"RY"}>Runyakitara</MenuItem>
            </Select>
          </FormControl>

          <ResourceButton
            publishedDate={article?.publishedAt}
            addResourceUrl={URL.RESOURCE}
            ListingUrl={URL.LEARNING_RESOURCE_LISTING}
            error={error}
          />
        </Box>
        
      </Box>
    </>
  );
};

export default Title;

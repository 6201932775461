import React, { useEffect, useState } from "react";
import CoverImage from "../common/CoverImage";
import Address from "./Address";
import EventDetail from "./EventDetail";
import Organizer from "./Organizer";
import EventHeader from "./EventHeader";
import EventCategories from "./EventCategories";
import TargetAudience from "./TargetAudience";
import {
  clearCurrentEvent,
  retrieveEventbyId,
  setCoverImage,
} from "../../store/actions/currentEvent";
import { useDispatch, useSelector } from "react-redux";
import EventDate from "./EventDate";
import { useLocation, useParams } from "react-router";
import EventButtons from "./EventButtons";
import EventLanguage from "./EventLanguage";
import { getRequiredAspectRatio } from "../articles/create-article/Helper";
import { Box } from "@mui/material";
import EventLabel from "./EventLabel";
import EventNotifications from "./EventNotifications";
import Link from "./Link";
import MultiAttachment from "./MultiAttachment";
const recommendedSizeMessage =
  "Best results with a 16:9 image, like 640x360 or 960x540.";
const EventForm = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const languageSelector = useSelector((state) => state.currentEvent.language);
  const selectedCoverImage = useSelector(
    (state) => state.currentEvent.coverImage[languageSelector]
  );
  const [dateError, setDateError] = useState("");

  const requiredAspectRatio = getRequiredAspectRatio();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(retrieveEventbyId(id));
    } else {
      dispatch(clearCurrentEvent());
    }
  }, [dispatch, id]);
  return (
    <>
      <EventHeader dateError={dateError} />

      <Box sx={{ mb: 2 }}>
        <CoverImage
          setCoverImage={setCoverImage}
          requiredAspectRatio={requiredAspectRatio}
          selectedCoverImage={selectedCoverImage}
          recommendedSize={recommendedSizeMessage}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Address />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Link />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventDetail />
      </Box>

      <Box sx={{ mb: 2 }}>
        <TargetAudience />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventLanguage />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventDate setDateError={setDateError} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Organizer />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventCategories />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventLabel />
      </Box>
      <Box sx={{ mb: 2 }}>
        <MultiAttachment title={"Attachments"} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventNotifications title={"Notifications"} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <EventButtons dateError={dateError} />
      </Box>
    </>
  );
};

export default EventForm;

import { trackPromise } from "react-promise-tracker";
import api from "../../services/api";
import {
  GET_EVENT_REQUESTS_DATA_FAILURE,
  GET_EVENT_REQUESTS_DATA_SUCCESS,
  REJECT_EVENT_REQUESTS_FAILURE,
  REJECT_EVENT_REQUESTS_SUCCESS,
  SET_SELECTED_EVENT_REQUEST,
} from "./type";
import { prepareEventRequestPayload } from "../../component/common/GeneralHelper";
import { EVENT_STATUS } from "../../constants/GeneralConstant";

export const getEventRequestsData = (queryParam) => async (dispatch) => {
  try {
    const res = await trackPromise(
      api.post("/event-management/event-requests/list", queryParam)
    );

    dispatch({
      type: GET_EVENT_REQUESTS_DATA_SUCCESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_EVENT_REQUESTS_DATA_FAILURE, payload: err.message });
  }
};

export const setSelectedEventRequest = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_EVENT_REQUEST,
    payload: data,
  });
};

export const cancelRequest = (data) => async (dispatch) => {};

export const rejectRequest = (data) => async (dispatch) => {
  try {
    const { id } = data;
    const payload = prepareEventRequestPayload(
      data,
      EVENT_STATUS.REJECT,
      EVENT_STATUS.PUBLISHED
    );
    const res = await trackPromise(
      api.patch(`/event-management/event-request/${id}`, payload)
    );

    dispatch({
      type: REJECT_EVENT_REQUESTS_SUCCESS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch({ type: REJECT_EVENT_REQUESTS_FAILURE, payload: err.message });
    return Promise.reject(err.response.data);
  }
};

export const approveRequest = (data) => async (dispatch) => {
  try {
    const { id } = data;
    const payload = prepareEventRequestPayload(
      data,
      EVENT_STATUS.APPROVED,
      EVENT_STATUS.PUBLISHED
    );
    const res = await trackPromise(
      api.patch(`/event-management/event-request/${id}`, payload)
    );

    dispatch({
      type: REJECT_EVENT_REQUESTS_SUCCESS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch({ type: REJECT_EVENT_REQUESTS_FAILURE, payload: err.message });
    return Promise.reject(err.response.data);
  }
};

export const draftRequest = (data) => async (dispatch) => {
  try {
    const { id } = data;
    const payload = prepareEventRequestPayload(
      data,
      EVENT_STATUS.DRAFT,
      EVENT_STATUS.PUBLISHED
    );
    const res = await trackPromise(
      api.patch(`/event-management/event-request/${id}`, payload)
    );

    dispatch({
      type: REJECT_EVENT_REQUESTS_SUCCESS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch({ type: REJECT_EVENT_REQUESTS_FAILURE, payload: err.message });
    return Promise.reject(err.response.data);
  }
};

export const markRequestAsRead = (payload) => async (dispatch) => {
  const res = await trackPromise(
    api.post(`/event-management/event-request/mark-read`, payload)
  );
};

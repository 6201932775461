export const validateEvent = (
  event,
  eventTitle,
  eventAddress,
  eventCategory,
  eventAud,
  eventDetail,
  eventStart,
  eventEnd,
  dateError,
  eventLang,
  eventLabel,
  eventStartDateTime,
  eventEndDateTime,
  eventLink,
  notificationDate,
  notificationInterval
) => {
  if (!event.title[event.language]) {
    return "Title can't be blank";
  }
  if (!eventAddress && !eventLink) {
    return "Both address and link can't be blank";
  }
  if (event.categoryIds.length < 1) {
    return "Category is a required field";
  }
  if (!eventAud) {
    return "Audience can't be blank";
  }
  if (!eventLang) {
    return "Select Event Language";
  }
  if (!eventDetail) {
    return "Event Details can't be blank";
  }
  if (!eventStart) {
    return "Select start Date & Time";
  }
  if (!eventEnd) {
    return "Select end Date & Time";
  }
  if (dateError === "Invalid") {
    return "Invalid End Date & Time";
  }
  if (!event.externalOrganizer && !event.organizerId.id) {
    return "Choose Organizer";
  }
  if (!eventLabel) {
    return "Select Event Label";
  }

  if (!eventStartDateTime) {
    return "Select Event Start Date & Time";
  }

  if (!eventEndDateTime) {
    return "Select Event End Date & Time";
  }

  if (!notificationDate) {
    return "Select Event Notification Date";
  }
  if (event.notificationFrequency === 2 && notificationInterval.length > 0) {
    return "Select Notification Interval";
  }
  return null;
};

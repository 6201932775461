/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { toggleTickActivation } from "../../../store/actions/learningResource";
import styled from "@emotion/styled";
import { formatDate } from "../../common/DateFormatter";
import { Tooltip, Box, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { getStatusClass } from "../../common/GeneralHelper";

const CustomDataGrid = styled(DataGrid)(() => ({
  ".MuiDataGrid-iconButtonContainer": {
    visibility: "visible",
    width: "0 !important",
  },
}));
function CustomUnsortedIcon() {
  return <UnfoldMoreIcon />;
}


const ArticleListTable = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { learningResource } = useSelector((state) => state.learningResource);
  const articles = learningResource.filter(
    (article) => article.learningResourceType === "Tick"
  );
  const isMobile = useMediaQuery("(max-width:1024px)");



  const columns = [
    { 
      field: "bodyPart", 
      headerName: "Predilection", 
      width: isMobile ? 150 : 400, // Adjust width based on screen size
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMobile ? undefined : 0.6, // Allow auto sizing for mobile
      width: isMobile ? 130 : undefined, // Adjust width based on screen size
      renderCell: (params) => {
        return (
          <div className={getStatusClass(params.row.status)}>
            {params.row.status}
          </div>
        );
      },
    },
    {
      field: "updatedat",
      headerName: "Last Updated",
      flex: isMobile ? undefined : 0.6, // Allow auto sizing for mobile
      width: isMobile ? 200 : undefined, // Adjust width based on screen size
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      flex: isMobile ? undefined : 1, // Allow auto sizing for mobile
      width: isMobile ? 150 : undefined, // Adjust width based on screen size
      renderCell: (params) => {
        if (params.row.bodyPart === "Full Body") {
          return (
            <Tooltip title="Edit">
              <FileOpenOutlinedIcon
                onClick={() => navigate(`/know-your-tick/${params.id}/edit`)}
              />
            </Tooltip>
          ); // return only the "Edit" button
        } else {
          params.sortDirection && (
            <span>{params.sortDirection === "asc" ? "▲" : "▼"}</span>
          );

          const HandleActivation = (params) => {
            const res = articles.find((obj) => {
              return obj.id === params.id;
            });
            dispatch(toggleTickActivation(params.id, res))
              .then(() => {
                if (res.status === "Published") {
                  toast.success("Successfully deactivated!");
                } else {
                  toast.success("Successfully activated!");
                }
              })
              .catch(() => {
                toast.error("Failed to gate/ungate resource!");
              });
          };
          const article = articles.find((obj) => obj.id === params.id);

          let activation;
          if (article && article.status === "Published") {
            activation = (
              <Tooltip title="deactivate">
                <VisibilityOutlinedIcon
                  onClick={() => HandleActivation(params)}
                />
              </Tooltip>
            );
          } else {
            activation = (
              <Tooltip title="activate">
                <VisibilityOffOutlinedIcon
                  onClick={() => HandleActivation(params)}
                />
              </Tooltip>
            );
          }

          return (
            <>
              <Tooltip title="Edit">
                <FileOpenOutlinedIcon
                  onClick={() => navigate(`/know-your-tick/${params.id}/edit`)}
                />
              </Tooltip>
              {activation}
            </>
          );
        }
      },
    },
  ];
  function CustomNoRowsOverlay() {
    return (
      <Box
        className="nofound-box"
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <span className="nofound-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="gray"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            width="24"
            height="24"
          >
            <path d="M10.29 3.86L1.82 19a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3l-8.47-15.14a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <circle cx="12" cy="17" r="1"></circle>
          </svg>
        </span>
        <h4 className="title">No results found!</h4>
        <h5 className="sub-title">
          Try changing the search criteria and try again
        </h5>
      </Box>
    );
  }

  const rows = articles.map((item) => ({
    id: item.id,
    bodyPart: item.vBodyPart.EN || item.vBodyPart.LU || item.vBodyPart.RY,
    status: item.status,
    updatedat:
      formatDate(item.updatedAt).date +
      " | " +
      formatDate(item.updatedAt).time +
      " by " +
      (item.updatedBy && item.updatedBy.fullname
        ? item.updatedBy.fullname
        : "Admin"),
  }));

  const rows2 = data.map((item) => ({
    id: item.id,
    bodyPart: item.vBodyPart.EN || item.vBodyPart.LU || item.vBodyPart.RY,
    status: item.status,
    updatedat:
      formatDate(item.updatedAt).date +
      " | " +
      formatDate(item.updatedAt).time +
      " by " +
      (item.updatedBy && item.updatedBy.fullname
        ? item.updatedBy.fullname
        : "Admin"),
  }));

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 172px)",
          '@media (max-width: 767px)': {
            height: 'calc(100vh - 240px)', // Full width for screens <= 767px
          },
        }}
      >
        {data || data?.length > 1 ? (
          <CustomDataGrid
            rows={rows2}
            columns={columns}
            pageSize={20}
            disableColumnMenu
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedAt", sort: "desc" }],
              },
            }}
          />
        ) : (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            disableColumnMenu
            rowsPerPageOptions={[20]}
            options={{
              showSelectAllCheckbox: false,
            }}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "updatedAt", sort: "desc" }],
              },
            }}
          />
        )}
      </Box>
    </>
  );
};

export default ArticleListTable;

import React, { useEffect, useState } from "react";
import { WithHeader } from "../common/WithHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import { Stack } from "@mui/system";
import { setEventLabel } from "../../store/actions/currentEvent";

const Label = () => {
  const dispatch = useDispatch();
  const [errorFullname, setErrorFullname] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);

  const selectedLabel = useSelector((state) => state.currentEvent.eventLabel);

  const eventLabels = [
    {
      id: "Elanco",
      label: "Elanco",
    },
    {
      id: "External",
      label: "External",
    },
  ];

  const handleOrganizerChange = (event) => {
    const optionId = event.target.value;
    dispatch(setEventLabel(optionId));
  };

  const getAuthorRow = (item) => {
    const fullname = item.label;

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          key={item.id}
          alignItems="center"
          direction="row"
          sx={{ p: "4px 0" }}
        >
          <FormControlLabel
            sx={{
              m: 0,
            }}
            key={`${item.id}-FormControlLabel`}
            value={item.label}
            control={<Radio key={item.id} />}
          />
          <CardHeader
            sx={{
              p: 0,
              ml: 1,
            }}
            titleTypographyProps={{
              fontSize: "14px", // Adjusts the font size of the MuiTypography-root
            }}
            title={fullname}
            key={`${item.id}-CardHeader`}
          />
        </Stack>
      </Box>
    );
  };
  return (
    <>
      <RadioGroup value={selectedLabel} onChange={handleOrganizerChange}>
        {eventLabels.length > 0 &&
          eventLabels.map((item) => getAuthorRow(item))}
      </RadioGroup>
    </>
  );
};

export default WithHeader(Label, "Label *");
